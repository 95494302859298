<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :title="$t('label.tables')" @on-cancel="close">
    <div class="content">
      <Spin v-if="api_requests.num_pending > 0" fix></Spin>

      <div v-if="tables.length" class="tables">
        <Table :data="tables" :columns="columns" :max-height="600" size="small">
          <template slot="actions" slot-scope="{ row }">
            <ButtonGroup size="small">
              <Button icon="ios-folder-open-outline" @click="loadTable(row)">{{ $t('action.open') }}</Button>
            </ButtonGroup>
          </template>
        </Table>
      </div>

      <div class="create-table">
        <Input v-model="new_table_name" :placeholder="$t('placeholder.table-name')"></Input>
        <Button type="primary" icon="ios-add-circle-outline" :disabled="!can_create_table" @click="createTable">{{$t('action.create')}}</Button>
      </div>
    </div>
    <div slot="footer">
      <Button @click="close">{{$t('action.cancel')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    data() {
      return {
        is_open: false,
        tables: [],
        new_table_name: '',
        columns: [
          {
            key: 'name',
            minWidth: 180,
            sortable: true,
            title: this.$t('label.name')
          },
          {
            key: 'rows',
            minWidth: 100,
            sortable: true,
            title: this.$t('label.rows')
          },
          {
            key: 'updatedAt',
            minWidth: 180,
            sortable: true,
            title: this.$t('label.updated-at'),
            render(h, params) {
              return h('span', moment(params.row.updatedAt).format('DD.MM.YYYY HH:mm:ss'))
            }
          },
          {
            key: 'createdAt',
            minWidth: 180,
            sortable: true,
            title: this.$t('label.created-at'),
            render(h, params) {
              return h('span', moment(params.row.createdAt).format('DD.MM.YYYY HH:mm:ss'))
            }
          },
          {
            key: 'import_filename',
            minWidth: 400,
            title: this.$t('label.import-filename'),
          },
          {
            title: ' '
          },
          {
            title: ' ',
            width: 100,
            fixed: 'right',
            slot: 'actions'
          }
        ]
      }
    },
    computed: {
      can_create_table() {
        return this.new_table_name && !_.find(this.tables, { name: this.new_table_name })
      }
    },
    watch: {
      'new_table_name'() {
        this.$nextTick(() => {
          this.new_table_name = _.replace(this.new_table_name, /[.$=_|#<>\][{}\\/]/g, '')
        })
      }
    },
    methods: {
      async open() {
        this.is_open = true
        this.tables = await this.apiGet('tables', '/tables')
      },
      close() {
        this.is_open = false
        this.apiCancelAll()
      },
      loadTable(t) {
        this.is_open = false
        this.$store.dispatch('table/load', t._id)
          //.then(() => this.$router.push({ name: 'table' }))
      },
      async createTable() {
        const data = { name: this.new_table_name }
        const table = await this.apiPost('table', { url: '/table', data })
        this.new_table_name = ''
        this.loadTable(table)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .content {
    position: relative;
  }

  .tables {
    margin-bottom: 1rem;
  }

  .create-table {
    display: flex;
    padding-left: 50%;

    > div {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
