const state = {
  last_summary_header: ''
}

const mutations = {
  setLastSummaryHeader(state, h) {
    state.last_summary_header = h || ''
  }
}

export default { namespaced: true, state, mutations }
