<template>
  <div v-show="value.length || !readonly" class="tag-list">
    <Tag v-for="t in value" :key="t" :closable="!readonly" @on-close="removeTag(t)">{{t}}</Tag>
    <!--<Input v-model.trim="add_tag" size="small" class="add-tag" :placeholder="$t('placeholder.new-tag')" @on-enter="addTag"></Input>-->
    <AutoComplete v-if="!readonly" v-model.trim="add_tag" :data="suggestions" size="small" class="add-tag" :placeholder="$t('placeholder.new-tag')" @keyup.enter.native="addTag()" @on-select="addTag($event)"></AutoComplete>
  </div>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    props: {
      value: {
        type: Array,
        required: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        add_tag: '',
        suggestions: []
      }
    },
    watch: {
      'add_tag'() {
        this.$nextTick(() => {
          this.add_tag = _.replace(this.add_tag, /[.$=|#<>\][{}\\/]/g, '')
          if (_.size(this.add_tag)) {
            this.refreshSuggestions()
          }
        })
      }
    },
    methods: {
      removeTag(t) {
        this.$emit('input', _.without(this.value, t))
      },
      addTag(v) {
        v = _.trim(v || this.add_tag)
        if (this.readonly || !v) {
          return
        }
        if (this.value.indexOf(v) >= 0) {
          return
        }
        this.$emit('input', _.concat(this.value, v))
        this.add_tag = ''
      },
      async refreshSuggestions() {
        this.suggestions = await this.apiPost('suggestions', { url: '/tags', data: { query: this.add_tag } })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .tag-list {
    border-radius: $input-border-radius;
    padding: $input-border-radius;
    line-height: 120%;
    border: 1px solid $col-border;
  }

  .add-tag {
    width: 120px;
    margin: 2px 0;
  }
</style>
