<template>
  <OffSiteMask>
    <Form ref="form" :model="form" :rules="rules" @submit.native.prevent>
      <FormItem prop="username">
        <Input v-model.trim="form.username" type="text" :placeholder="$t('label.username')" @on-enter="handleLogin">
          <Icon slot="prepend" type="ios-person-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="password">
        <Input v-model.trim="form.password" type="password" :placeholder="$t('label.password')" @on-enter="handleLogin">
          <Icon slot="prepend" type="ios-lock-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem v-if="error">
        <Alert type="error" show-icon>
          {{ $t('label.error') }}:
          <span slot="desc">{{ error }}</span>
        </Alert>
      </FormItem>
      <FormItem class="actions">
        <Button type="text" :disabled="!can_request_reset" @click="requestReset">{{ $t('login.forgot-password') }}</Button>
        <Button icon="ios-log-in" @click="handleLogin">{{ $t('action.login') }}</Button>
      </FormItem>
    </Form>
  </OffSiteMask>
</template>

<script>
  import APIMixin from '/mixins/api'
  import OffSiteMask from '/components/off-site-mask'

  const PRODUCTION = process.env.NODE_ENV === 'production'
  const MIN_PASSSWORD_LEN = 8

  export default {
    components: {
      OffSiteMask
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        error: '',
        form: {
          username: PRODUCTION ? '' : 'volker@arweiler.pro',
          password: PRODUCTION ? '' : 'testomat'
        },
        rules: {
          password: [
            {
              type: 'string',
              min: MIN_PASSSWORD_LEN,
              message: this.$t('login.hint.min-password-length', { length: MIN_PASSSWORD_LEN }),
              trigger: 'blur'
            }
          ]
        }
      }
    },
    computed: {
      complete() {
        return !!(this.form.username && this.form.password)
      },
      can_request_reset() {
        return !!this.form.username
      }
    },
    mounted() {
      this.$api.setToken(null)
      this.$store.commit('auth/setUser', null)
    },
    methods: {
      formatError(err) {
        let msg = err.message && `login.error.${err.message}`
        msg = msg || `login.error.${err}`
        return (msg && this.$te(msg)) ? this.$t(msg) : (err.message || err)
      },
      handleLogin() {
        if (!this.complete) {
          return
        }
        this.$refs.form.validate((v) => {
          if (!v) {
            return
          }
          this.$store.dispatch('auth/login', this.form)
            .then(() => {
              this.$router.replace(this.$route.query.redirect || '/')
            })
            .catch((err) => {
              console.error(err)
              this.error = this.formatError(err)
              this.form.password = ''
            })
        })
      },
      requestReset() {
        const data = { username: this.form.username }
        this.apiPost('request', { url: '/request-pwd', data })
          .then(() => {
            this.$Modal.info({
              title: this.$t('label.tip'),
              content: this.$t('message.password-reset-request-sent')
            })
          }, (err) => {
            this.$reportError(this.formatError(err))
            console.error(err)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .actions {
    text-align: right;
  }
</style>
