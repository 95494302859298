<template>
  <div class="column-corrector">
    <div class="heading">{{ $t("label.column-corrector") }}:</div>
    <div class="inner">
      <Spin v-if="api_requests.num_pending > 0" fix></Spin>
      <div class="padded select-controls">
        <Select
          :value="header"
          :placeholder="$t('placeholder.select_column')"
          @on-change="onChange($event)"
        >
          <Option v-for="h in available_header" :key="h" :value="h">
            {{h}}
          </Option>
        </Select>
        <Button :disabled="!header" @click="reinit(header)">{{ $t('action.refresh') }}</Button>
      </div>

      <div v-if="errors" :key="errors.header" class="lists-wrap">
        <div class="padded selection-heading">
          Verbleibende Zellenwerte:
        </div>

        <div class="value-list-wrap">
          <List
            ref="value_list"
            :columns="list_columns"
            :data="errors.values"
            class="value-list padded"
            @dblclick-item="onDblClick"
          >
            <div slot="list-actions" style="padding-top:2px;">
              <Button :disabled="!errors.values.length" size="small" icon="ios-add-circle" @click="onAddAll">{{ $t('label.all') }}</Button>
            </div>
            <span
              :slot="header"
              slot-scope="{ item }"
              class="header-value"
              >{{ item[errors.header] }}</span>

            <span slot="actions" slot-scope="{ item }" class="actions">
              <span class="action-btn" @click="onAdd(item)">
                <Icon type="ios-add-circle-outline"></Icon>
              </span>
            </span>
          </List>
        </div>

        <div class="padded selection-heading">
          Ausgewählte Zellenwerte:
        </div>

        <div class="padded big-actions">
          <div>
            <Button
              type="success"
              icon="ios-funnel"
              style="display:block; width: 100%;"
              :disabled="!selected_values.length"
              @click="onSelectSelected"
            >
              Neue Selektion aus <b>{{ selected_values.length }}</b> Zellenwerten
            </Button>
          </div>

          <div>
            <Button :disabled="!selected_values.length" icon="ios-remove-circle" @click="onRemoveAll">{{ $t('label.all') }}</Button>
          </div>
        </div>

        <div class="value-list-wrap">
          <List
            ref="selected_list"
            :columns="selected_columns"
            :data="selected_values"
            class="value-list padded"
            @dblclick-item="onDblClick"
          >
            <span
              :slot="header"
              slot-scope="{ item }"
              class="header-value"
              >{{ item[errors.header] }}</span>

            <span slot="actions" slot-scope="{ item }" class="actions">
              <span class="action-btn" @click="onRemove(item)">
                 <Icon type="ios-remove-circle-outline"></Icon>
              </span>
            </span>
          </List>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import APIMixin from "/mixins/api";
import List from "/components/list";

const SECONDARY_COL_WIDTH = '20%'

export default {
  components: {
    List
  },
  mixins: [APIMixin],
  props: ["scrollToHeader"],
  data() {
    return {
      errors: null,
      selected_values: []
    };
  },
  computed: {
    header: {
      get() {
        return this.$store.state.ui.last_summary_header;
      },
      set(v) {
        this.$store.commit("ui/setLastSummaryHeader", v);
      }
    },
    available_header() {
      //const header = _.reject(this.$store.state.table.header, (h) => this.$store.getters['table/headerInfos'][h].hidden)
      const header = _.clone(this.$store.state.table.header);
      header.sort((a, b) => {
        return a.localeCompare(b, "de", { numeric: true });
      });
      return header;
    },
    list_columns() {
      if (!this.errors) {
        return []
      }
      const cols = _.map(this.errors.involved, (h) => {
        return {
          key: h,
          filter: h === this.errors.header,
          sortable: true,
          label: h,
          width: h === this.errors.header ? undefined : SECONDARY_COL_WIDTH
        }
      })
      cols.push({
        key: '_t',
        sortable: true,
        width: 80,
        align: "right",
        label: this.$t('label.count')
      })
      cols.push({
        width: 42,
        align: "center",
        slot: "actions"
      })
      return cols
    },
    selected_columns() {
      if (!this.errors) {
        return []
      }
      const cols = _.map(this.errors.involved, (h) => {
        return {
          key: h,
          sortable: true,
          label: h,
          width: h === this.errors.header ? undefined : SECONDARY_COL_WIDTH
        }
      })
      cols.push({
        key: '_t',
        sortable: true,
        width: 80,
        align: "right",
        label: this.$t('label.count')
      })
      cols.push({
        width: 42,
        align: "center",
        slot: "actions"
      })
      return cols
    }
  },
  created() {
    this._unsubscribeStore = this.$store.subscribe((mutation, state) => {
      if (_.includes(["table/_load", "table/reset"], mutation.type)) {
        this.reinit(state.table._id ? this.header : null);
      }
    });
    this.$bus.$on("header-sorted", this.onHeaderSorted);
    this.$bus.$on("mapping-performed", this.onMappingPerformed);
    if (this.header) {
      this.reinit(this.header);
    }
  },
  beforeDestroy() {
    this.$bus.$off("mapping-performed", this.onMappingPerformed);
    if (this._unsubscribeStore) {
      this._unsubscribeStore();
    }
  },
  methods: {
    async reinit(header) {
      if (this.$refs.value_list) {
        this.$refs.value_list.setFilterText("");
      }
      this.header = header || "";
      if (!this.header) {
        this.errors = null;
        return;
      }
      this.errors = await this.apiPost({
        slot: "reinit",
        url: `/table/${this.$store.state.table._id}/errors`,
        data: {
          header: this.header
        }
      })
      this.selected_values = [];
    },
    onChange(header) {
      this.reinit(header);
      if (header && this.scrollToHeader) {
        this.scrollToHeader(header);
      }
    },
    onMappingPerformed(res, req) {
      if (!this.errors) {
        return
      }
      //this.reinit(this.header)
      if (req.header !== this.header) {
        return this.reinit(this.header)
      }
      const h = this.errors.header
      const rv = _.concat(_.keys(res.mapped), _.keys(res.existing))

      this.errors.values = _.filter(this.errors.values, (v) => {
        return _.includes(rv, v[h]) ? null : v
      })
      this.errors.values = _.compact(this.errors.values)

      this.selected_values = _.filter(this.selected_values, (v) => {
        return _.includes(rv, v[h]) ? null : v
      })
      this.selected_values = _.compact(this.selected_values)

      this.$refs.value_list.reset()
    },
    onHeaderSorted() {
      this.reinit(this.header);
    },
    onSelectSelected() {
      let rows = [];
      for (const v of this.selected_values) {
        rows = _.concat(rows, v._i);
      }
      rows = _.flatten(rows);
      rows = _.uniq(rows);
      if (!_.isEmpty(rows)) {
        this.$store.commit("selection/setMultiple", {
          header: this.errors.header,
          rows
        });
        this.$store.commit("selection/setFocused", true);
      }
    },
    onAdd(item) {
      this.selected_values.push(item);

      this.errors.values = _.reject(this.errors.values, (v) => {
        return _.every(this.errors.involved, (h) => v[h] === item[h])
      })
    },
    onAddAll() {
      for (const item of this.$refs.value_list.filtered_items) {
        this.selected_values.push(item.value);
        const idx = _.findIndex(this.errors.values, (v) => {
          return _.every(this.errors.involved, (h) => v[h] === item.value[h])
        })
        if (idx >= 0) {
          this.errors.values.splice(idx, 1)
        }
      }
    },
    onRemove(item) {
      this.errors.values.push(item);

      this.selected_values = _.reject(this.selected_values, (v) => {
        return _.every(this.errors.involved, (h) => v[h] === item[h])
      })
    },
    onRemoveAll() {
      for (const item of this.selected_values) {
        this.errors.values.push(item)
      }
      this.selected_values = []
    },
    onDblClick() {
      let text = ''
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text;
      }
      text = _.trim(text)
      if (text) {
        this.$refs.value_list.setFilterText(text)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/styles/theme";

.column-corrector {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .inner {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.heading {
  font-weight: bold;
  background-color: $col-background;
  padding: 0.5rem;
  font-size: 1.16em;
  user-select: none;
}

.padded {
  padding: 0.5em;
}

.selection-heading {
  background-color: $col-border;
  font-weight: bold;
  margin-top: 1rem;
}

.select-controls {
  display: flex;

  .ivu-select {
    flex: 1;
    margin-right: 1rem;
  }
}

.lists-wrap {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.value-list-wrap {
  position: relative;
  flex: 1;
}

.value-list {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .header-value {
    color: $col-error;
  }
}

.actions {
  display: flex;

  > span:first-child {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.big-actions {
  display: flex;

  > div {
    margin-right: 1em;

    &:first-child {
      flex: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
