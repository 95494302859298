<template>
  <Form :label-width="80" @submit.native.prevent>
    <FormItem :label="$t('label.format')">
      <Select v-model="transform.format" :disabled="readonly">
        <Option value="integer">{{$t('number-format.integer')}}</Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly' ]
  }
</script>
