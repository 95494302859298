<template>
  <OffSiteMask>
    <Form ref="form" :model="form" :rules="rules" @submit.native.prevent>
      <FormItem prop="username">
        <Input v-model.trim="form.username" :placeholder="$t('label.username')">
          <Icon slot="prepend" type="ios-person-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="code">
        <Input v-model.trim="form.code" :placeholder="$t('label.reset_code')">
          <Icon slot="prepend" type="ios-barcode-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="password">
        <Input v-model.trim="form.password" type="password" :placeholder="$t('label.password')">
          <Icon slot="prepend" type="ios-lock-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="password2">
        <Input v-model.trim="form.password2" type="password" :placeholder="$t('label.repeat_password')">
        <Icon slot="prepend" type="ios-lock-outline" :size="16"></Icon>
        </Input>
      </FormItem>
      <FormItem class="actions">
        <Button icon="ios-log-in" :disabled="!complete" @click="handleReset">{{ $t('action.reset') }}</Button>
      </FormItem>
    </Form>
  </OffSiteMask>
</template>

<script>
  import APIMixin from '/mixins/api'
  import OffSiteMask from '/components/off-site-mask'

  const MIN_PASSSWORD_LEN = 8

  export default {
    components: {
      OffSiteMask
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        error: '',
        form: {
          username: '',
          code: '',
          password: '',
          password2: '',
        },
        rules: {
          password: [
            {
              type: 'string',
              min: MIN_PASSSWORD_LEN,
              message: this.$t('login.hint.min-password-length', { length: MIN_PASSSWORD_LEN }),
              trigger: 'blur'
            }
          ],
          password2: [
            {
              type: 'string',
              min: MIN_PASSSWORD_LEN,
              message: this.$t('login.hint.min-password-length', { length: MIN_PASSSWORD_LEN }),
              trigger: 'blur'
            }
          ]
        }
      }
    },
    computed: {
      complete() {
        return !!(this.form.username && this.form.code && this.form.password && this.form.password === this.form.password2)
      }
    },
    mounted() {
      this.$api.setToken(null)
      this.$store.commit('auth/setUser', null)
      this.form.username = this.$route.query.username || ''
      this.form.code = this.$route.query.code || ''
    },
    methods: {
      formatError(err) {
        let msg = err.message && `login.error.${err.message}`
        msg = msg || `login.error.${err}`
        return (msg && this.$te(msg)) ? this.$t(msg) : (err.message || err)
      },
      handleReset() {
        this.apiPost('reset', { url: '/reset-pwd', data: this.form })
          .then(() => {
            this.$router.replace({ name: 'login' })
          }, (err) => {
            this.$reportError(this.formatError(err))
            console.error(err)
            this.form.password = this.form.password2 = ''
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .actions {
    text-align: right;
  }
</style>
