import _isNumber from 'lodash/isNumber'

export function isDocumentHidden() {
  if ('hidden' in document) {
    return window.document.hidden
  }
  const prefixes = [ 'webkit', 'moz', 'ms', 'o' ]
  for (let i = 0; i < prefixes.length; ++i) {
    const key = prefixes[i] + 'Hidden'
    if (key in window.document) {
      return window.document[key]
    }
  }
  return false
}

export default {
  created() {
    this._method_intervals = {}
  },
  beforeDestroy(){
    for (var k in this._method_intervals) {
      if (this._method_intervals[k]) {
        clearInterval(this._method_intervals[k])
        this._method_intervals[k] = null
      }
    }
  },
  methods: {
    cancelMethodInterval(slot) {
      if (this._method_intervals[slot]) {
        clearInterval(this._method_intervals[slot])
        this._method_intervals[slot] = null
      }
    },
    methodInterval(slot, fn, opt) {
      if (opt === undefined) {
        opt = fn
        fn = slot
        slot = 'default'
      }
      if (_isNumber(opt)) {
        opt = { interval: opt }
      }
      opt.interval = _isNumber(opt.interval) ? opt.interval : 1000
      if (this._method_intervals[slot]) {
        clearInterval(this._method_intervals[slot])
      }
      this._method_intervals[slot] = setInterval(() => {
        if (!opt.skip_hidden || !isDocumentHidden()) {
          this.$nextTick(fn)
        }
      }, opt.interval)
    }
  }
}
