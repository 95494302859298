import api from '../services/api'

const state = {
  user: null
}

const mutations = {
  setUser(state, user) {
    state.user = user || null
  }
}

const actions = {
  async login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      api.post('/login', credentials)
        .then((res) => {
          api.setToken(res.token)
          commit('setUser', res.user)
          resolve(res.user)
        })
        .catch(reject)
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      api.setToken(null)
      commit('setUser', null)
      resolve()
    })
  },
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/refresh')
        .then((res) => {
          commit('setUser', res)
          resolve(res)
        })
        .catch((err) => {
          console.error(err)
          commit('setUser', null)
          reject(err)
        })
    })
  }
}

export default { namespaced: true, state, mutations, actions }
