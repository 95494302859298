<template>
  <div class="spinner-progress">
    <Spin>
      <Icon type="ios-loading" :size="32" class="spin-icon-load"></Icon>
      <div v-if="text" class="text">{{text}}</div>
    </Spin>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .spinner-progress {
    text-align: center;
    padding: 1rem 1rem;
  }

  .spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
    margin-bottom: 0.5rem;
  }

  .text {
    color: $col-content;
  }
</style>
