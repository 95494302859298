<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem>
      <List ref="value_list" :data="transform.values" :columns="value_columns" class="value-list">
        <span slot="value" slot-scope="{ item }">
          {{item}}
        </span>
        <span slot="actions" slot-scope="{ item }">
          <span class="action-btn danger" @click="deleteItem(item)">
            <Icon type="ios-trash-outline"></Icon>
          </span>
        </span>
      </List>
    </FormItem>

    <FormItem v-if="!readonly">
      <div class="add-inputs">
        <Input v-model.trim="add_value" :placeholder="$t('placeholder.cellvalue')" @on-enter="addValue">
          <Button slot="append" icon="ios-add-circle-outline" :disabled="!can_add" @click="addValue">{{$t('action.add')}}</Button>
        </Input>
      </div>
    </FormItem>

    <TransformImportExport v-if="!readonly" :config="config" :transform-index="index" prefix="Auflistung" @imported="$emit('refresh')"></TransformImportExport>
  </Form>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import TransformImportExport from './transform-import-export'

  export default {
    components: {
      List,
      TransformImportExport
    },
    props: [ 'config', 'index', 'transform', 'readonly' ],
    data() {
      return {
        add_value: '',
        max_shown: 100
      }
    },
    computed: {
      value_columns() {
        return _.compact([
          {
            slot: 'value',
            filter: 'VALUE',
            sortable: true,
            label: this.$t('label.cellvalue')
          },
          !this.readonly && {
            slot: 'actions',
            width: 40,
            align: 'center'
          }
        ])
      },
      can_add() {
        return this.add_value && this.transform.values.indexOf(this.add_value) < 0
      }
    },
    methods: {
      addValue() {
        if (!this.can_add) {
          return
        }
        this.transform.values.unshift(this.add_value)
        this.$refs.value_list.setFilterText(this.add_value)
        this.add_value = ''
      },
      deleteItem(val) {
        this.transform.values = _.reject(this.transform.values, (v) => v === val)
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .search-box {
    margin-bottom: 10px;
  }

  .add-inputs {
    display: flex;

    .spacer {
      margin: 0 0.5rem;
    }

    .el-button {
      margin-left: 0.5rem
    }
  }

  .value-list {
    height: 300px;
  }
</style>
