<template>
  <div class="view-off-site">
    <Card>
      <div slot="title" class="title">
        <div class="logo"></div>
        <div class="text"><b>24</b>myCleanData</div>
      </div>
      <slot></slot>
      <div class="info">
        <div class="copyright" v-html="$t('copyright')"></div>
        <div class="version">v{{version}}</div>
        <div class="links">
          <RouterLink :to="{ name: 'imprint' }">{{$t('label.imprint')}}</RouterLink>
          <span>&#32;|&#32;</span>
          <RouterLink :to="{ name: 'privacy' }">{{$t('label.privacy')}}</RouterLink>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  export default {
    computed: {
      version() {
        return process.env.PACKAGE_VERSION
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .view-off-site {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ivu-card {
    width: 400px;
  }

  .title {
    display: flex;
    align-items: center;
    //justify-content: center;
    font-size: 1.618rem;

    .logo {
      width: 1.4em;
      height: 1.4em;
      background-image: url("/assets/images/logo.svg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 0.25em;
    }

    .text {
      text-align: center;
      font-weight: 200;
      padding: 1rem 0;

      b {
        font-weight: 600;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    font-size: 10px;
    white-space: nowrap;
    color: $col-sub;

    > div {
      flex: 1;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .version {
      text-align: center;
    }

    .links {
      text-align: right;
    }
  }
</style>
