<template>
  <div class="not-ready">
    <template v-if="table.importing">
      <SpinnerProgress :text="$t('action.importing')"></SpinnerProgress>
      <!--<Button icon="ios-refresh" @click="onRefresh">{{$t('action.refresh')}}</Button>-->
    </template>
    <template v-else-if="table && table.import_errors && table.import_errors.length">
      <div style="margin-bottom:1rem;">{{$t('label.import_error')}}: <b>{{table.import_filename}}</b></div>
      <template v-for="(e, ei) in table.import_errors">
        <ErrorAlert :key="ei" :error="e">{{JSON.stringify(e)}}</ErrorAlert>
      </template>
    </template>
  </div>
</template>

<script>
  import MethodIntervalMixin from '/mixins/method-interval'
  import SpinnerProgress from '/components/spinner-progress'
  import ErrorAlert from '/components/error-alert'

  export default {
    components: {
      SpinnerProgress,
      ErrorAlert
    },
    mixins: [
      MethodIntervalMixin
    ],
    computed: {
      table() {
        return this.$store.state.table
      }
    },
    mounted() {
      this.methodInterval('refresh', this.onRefresh, 1000)
    },
    beforeDestroy() {
      this.cancelMethodInterval('refresh')
    },
    methods: {
      onRefresh() {
        if (!this.table) {
          return
        }
        if (!this.table.importing) {
          this.cancelMethodInterval('refresh')
          return
        }
        this.$store.dispatch('table/load', this.$store.state.table._id)
          .then(() => {
            if (this.table && !this.table.importing && this.table.rows) {
              this.$bus.$emit('open-import-wizard')
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/theme';

  .not-ready {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
