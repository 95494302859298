<template>
  <Form :label-width="80" @submit.native.prevent>
    <FormItem :label="$t('label.country')">
      <Select v-model="transform.country" :disabled="readonly">
        <Option v-for="c in options.valid_countries" :key="c" :value="c">{{$t(`country-name.${c}`)}}</Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'options', 'readonly' ]
  }
</script>
