<template>
  <div :class="[ 'cell', { selected, empty, corrected, error, editable, disabled } ]" @click.stop="onClick" @keyup.escape.prevent.stop="onEscape">
    <template v-if="editing">
      <Input ref="input" v-model="edit_value" type="text" class="cell-input" @on-blur="onBlur" @on-keyup="onInput"></Input>
    </template>
    <div v-else class="value">{{display_value}}</div>
    <div v-if="show_info_icon" @mouseenter.capture="show_tooltip = 'info'" @mouseleave="show_tooltip = false">
      <Icon type="ios-warning-outline" class="icon"></Icon>
    </div>
    <div v-if="show_comment_icon" @mouseenter.capture="show_tooltip = 'comment'" @mouseleave="show_tooltip = false">
      <Icon type="ios-pricetag-outline" class="icon"></Icon>
    </div>
    <div v-if="show_tooltip" class="tooltip-anchor">
      <div class="tooltip">
        <template v-if="show_tooltip === 'info'">
          <div v-if="error" class="section error">
            <div class="title">{{ $t('label.error') }}:</div>
            <div class="text">
              {{ $t('label.transform') }} {{result.error_step + 1}}.:&nbsp;
              {{ $te(`transform-error.${result.error}`) ? $t(`transform-error.${result.error}`) : result.error }}
            </div>
          </div>
          <div v-if="empty" class="section empty">
            <div class="title">{{ $t('label.empty') }}</div>
          </div>
          <div v-if="corrected" class="section correction">
            <div class="title">{{ $t('label.corrected') }}:</div>
            "<span class="cell-value">{{ value || empty_value }}</span>"
            &#32;
            <Icon type="ios-arrow-forward"></Icon>
            &#32;
            "<span class="cell-value">{{ result.value || empty_value }}</span>"
          </div>
        </template>
        <template v-else-if="show_tooltip === 'comment'">
          <div class="section comment">
            <div class="title">{{ $t('label.comment') }}:</div>
            <div class="text">{{ comment }}</div>
          </div>
        </template>
      </div>
    </div>
    <Suggestions v-if="editing" :up="bottomHalf" :items="suggestions" @select="onSelect"></Suggestions>
  </div>
</template>

<script>
  import _ from 'lodash'
  import Suggestions from './cell-suggestions'

  export default {
    components: {
      Suggestions
    },
    props: {
      header: {
        type: String,
        required: true
      },
      config: {
        type: Object,
        default: null
      },
      value: {
        type: [ String, Number ],
        default: ''
      },
      row: {
        type: Number,
        required: true
      },
      result: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      comment: {
        type: String,
        default: ''
      },
      getSuggestions: {
        type: Function,
        default: null
      },
      bottomHalf: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        editing: false,
        edit_value: '',
        show_tooltip: '',
        suggestions: null
      }
    },
    computed: {
      empty_value() {
        return this.$t('label.empty-value-indicator')
      },
      display_value() {
        return (this.result && !_.isUndefined(this.result.value)) ? this.result.value : this.value
      },
      selected() {
        const selection = this.$store.state.selection
        return this.header === selection.header && selection.rows.indexOf(this.row) >= 0
      },
      empty() {
        return !this.value && !!this.config
      },
      /*
      config() {
        return this.$store.getters['table/headerInfos'][this.header].config
      },
      */
      editable() {
        return !!this.value && this.config && this.config._can_map
      },
      corrected() {
        return this.result && !_.isUndefined(this.result.value)
      },
      error() {
        return this.result && !!this.result.error
      },
      show_info_icon() {
        return !this.editing && (this.error || this.corrected)
      },
      show_comment_icon() {
        return /*!this.editing && */!!this.comment
      }
    },
    watch: {
      selected(v) {
        if (!v && this.editing) {
          this.stopEdit(true)
        }
      }
    },
    methods: {
      onClick(e) {
        this.$bus.$emit('raw-cell-click')
        this.show_tooltip = ''

        if (this.editing) {
          if (e.altKey) {
            this.stopEdit(true)
            this.$store.commit('selection/reset')
          }
          return
        }
        /*
        if (!this.editable && !this.empty) {
          if (!this.$store.getters['selection/isEmpty']) {
            this.$store.commit('selection/reset')
          }
          return
        }
        */
        if (!e.altKey) {
          if (!this.selected) {
            this.$store.commit('selection/reset')
            this.$store.commit('selection/addRows', {
              header: this.header,
              rows: this.row
            })
          }
          if (this.editable && !this.disabled) {
            this.startEdit()
          }
          return
        }
        if (e.shiftKey) {
          let above = -1
          let below = -1
          for (const sri of this.$store.state.selection.rows) {
            if (sri > this.row && (sri < above || above === -1)) {
              above = sri
            }
            if (sri < this.row && (sri > below || below === -1)) {
              below = sri
            }
          }
          if (above >= 0 || below >= 0) {
            const d_above = above >= 0 ? Math.abs(this.row - above) : -1
            const d_below = below >= 0 ? Math.abs(this.row - below) : -1
            let start = -1
            if (d_above >= 0 && (d_below < 0 || d_above < d_below)) {
              start = above
            } else if (d_below >= 0 && (d_above < 0 || d_below < d_above)) {
              start = below
            }
            if (start >= 0) {
              let end = this.row
              if (start > end) {
                end = start
                start = this.row
              }
              const to_add = []
              for (let mi = start; mi <= end; ++mi) {
                to_add.push(mi)
              }
              this.$store.commit('selection/addRows', {
                header: this.header,
                rows: to_add
              })
            }
          }
        } else {
          this.$store.commit('selection/addRows', {
            header: this.header,
            rows: this.row,
            toggle: true
          })
        }
      },
      onEscape() {
        this.stopEdit(true)
      },
      startEdit() {
        this.editing = true
        this.edit_value = this.value
        this.$nextTick(() => {
          this.$refs.input.focus()
          this.$refs.input.$refs.input.select()
          this.onInput()
        })
      },
      stopEdit(cancel, val) {
        if (this.blur_timeout) {
          clearTimeout(this.blur_timeout)
          this.blur_timeout = 0
        }
        if (!this.editing) {
          return
        }
        this.suggestions = null
        this.editing = false
        val = _.trim(val)
        if (!cancel) {
          this.$emit('change', val || '')
        }
      },
      onBlur() {
        this.blur_timeout = setTimeout(() => {
          if (this.editing) {
            this.stopEdit(true)
          }
        }, 250)
      },
      onSelect(v) {
        this.stopEdit(false, v)
      },
      onInput(e) {
        if (e && e.key === 'Enter') {
          this.stopEdit(false, this.edit_value)
          return
        }
        if (this.config && this.getSuggestions) {
          this.getSuggestions(this.config._id, _.trim(this.edit_value))
            .then((res) => {
              this.suggestions = res
            }, () => {
              this.suggestions = null
            })
        } else {
          this.suggestions = null;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/theme';

  $col-cell-error: lighten(rgb(255, 130, 130), 17%);
  //$col-cell-error: #ffedee;
  $col-cell-empty: lighten(rgb(250, 250, 110), 20%);
  //$col-cell-empty: #ffffe0;
  $col-cell-corrected: lighten(rgb(130, 250, 130), 17%);
  //$col-cell-corrected: #edfced;

  .cell {
    height: $cell-height;
    border: 1px solid transparent;
    padding: 0 $cell-h-padding;
    transition: border-color 280ms ease;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.disabled {
      color: $col-disabled;
      cursor: default;
    }

    &.selected {
      border-color: $col-primary;
    }

    &.selected.editable {
      cursor: text;
    }

    &.error {
      background-color: $col-cell-error;
      //border-color: $col-extra-light-border;
    }

    &.corrected {
      background-color: $col-cell-corrected;
      //border-color: $col-extra-light-border;
    }

    &.empty {
      background-color: $col-cell-empty;
      //border-color: $col-extra-light-border;
    }

    &.error.corrected {
      background-image: linear-gradient(to right, $col-cell-error, $col-cell-error 35%, $col-cell-corrected 65%, $col-cell-corrected);
    }

    &.error.empty {
      background-image: linear-gradient(to right, $col-cell-error, $col-cell-error 35%, $col-cell-empty 65%, $col-cell-empty);
    }

    &.corrected.empty {
      background-image: linear-gradient(to right, $col-cell-empty, $col-cell-empty 35%, $col-cell-corrected 65%, $col-cell-corrected);
    }

    &.error.corrected.empty {
      background-image: linear-gradient(to right, $col-cell-error, $col-cell-error 20%, $col-cell-empty 40%, $col-cell-empty 60%, $col-cell-corrected 80%, $col-cell-corrected);
    }
  }

  .value {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
  }

  .icon {
    margin-left: $cell-h-padding;
    line-height: $cell-height;
    font-size: $cell-height - 6px;
  }

  .tooltip-anchor {
    position: relative;
  }

  .tooltip {
    position: absolute;
    left: 5px;
    top: $cell-height * -0.5;
    z-index: $z-cell-tooltip;
    width: 300px;
    background-color: #fff;
    line-height: 140%;
    //color: $col-primary-text;
    border: 1px solid $col-dark-border;
    box-shadow: 2px 2px 8px 3px rgba($col-dark-border, 0.5);

    .title {
      font-weight: bold;
      //font-size: 0.8em;
    }

    .text {
      font-style: italic;
    }

    .cell-value {
      white-space: pre;
      font-style: italic;
    }

    .section {
      padding: 4px 8px;

      &.correction {
        background-color: $col-cell-corrected;
      }

      &.error {
        background-color: $col-cell-error;
      }

      &.empty {
        background-color: $col-cell-empty;
      }
    }
  }
</style>

<style lang="scss">
  .cell-input {
    display: block;
    height: 100%;
    flex: 1;

    .ivu-input {
      display: block;
      border: 0;
      outline: none;
      background: transparent;
      height: 100%;
      line-height: 100%;
      border-radius: 0;
      padding: 0;
      font-style: italic;
    }
  }
</style>
