<template>
  <Form :label-width="150" @submit.native.prevent>
    <FormItem :label="$t('label.country')">
      <Select v-model="transform.country" :disabled="readonly">
        <Option v-for="c in options.countries" :key="c" :value="c">{{$t(`country-name.${c}`)}}</Option>
      </Select>
    </FormItem>
    <FormItem :label="$t('action.attempt_correction')">
      <i-switch v-model="transform.attempt_correction" :disabled="readonly"></i-switch>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly', 'options' ]
  }
</script>
