<template>
  <Form :label-width="150" @submit.native.prevent>
    <FormItem :label="$t('label.character_alpha')">
      <i-switch v-model="transform.alpha" :disabled="readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.character_digit')">
      <i-switch v-model="transform.digit" :disabled="readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.character_special')">
      <i-switch v-model="transform.special" :disabled="readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.check_special_characters')">
      <Select v-model="transform.special_chars" multiple="multiple" :disabled="!transform.special || readonly" style="width:100%;">
        <Option v-for="c in options.valid_specialchars" :key="c" :value="c">{{c}}</Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly', 'options' ]
  }
</script>
