<template>
  <div class="scrollbar" @mousedown="dragStart">
    <div :style="`top:${thumb_y}px;`" :class="{ thumb: true, dragging }">
      <span v-if="scroll_row">{{ scroll_row }}</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["firstRow"],
  data() {
    return {
      dragging: false,
      last_drag_y: 0
    };
  },
  computed: {
    num_total_rows() {
      if (this.$store.state.selection.focused) {
        return _.size(this.$store.state.selection.rows);
      } else {
        return this.$store.state.table.rows;
      }
    },
    thumb_y() {
      if (this.dragging) {
        return this.last_drag_y;
      } else {
        const firstrow = this.$store.state.selection.focused
          ? this.$store.state.selection.rows.indexOf(this.firstRow)
          : this.firstRow;
        const s = Math.min(Math.max(firstrow / this.num_total_rows, 0), 1);
        const rs = this.$el ? Math.floor(s * this.$el.clientHeight) : 0;
        return rs;
      }
    },
    scroll_row() {
      if (!this.dragging || !this.$el) {
        return 0;
      }
      const s = Math.min(
        Math.max(this.last_drag_y / this.$el.clientHeight, 0),
        1
      );
      const si = Math.floor(s * this.num_total_rows) + 1;
      if (this.$store.state.selection.focused) {
        return this.$store.state.selection.rows[si] || 1;
      } else {
        return si;
      }
    }
  },
  created() {
    document.addEventListener("mousemove", this.dragMove, true);
    document.addEventListener("mouseup", this.dragEnd, true);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.dragMove, true);
    document.removeEventListener("mouseup", this.dragEnd, true);
  },
  methods: {
    dragStart(e) {
      this.dragging = true;
      const bounds = this.$el.getBoundingClientRect();
      this.last_drag_y = Math.min(
        this.$el.clientHeight - 20,
        Math.max(0, e.clientY - bounds.top)
      );
    },
    dragEnd(e) {
      if (!this.dragging) {
        return;
      }
      this.dragging = false;
      const bounds = this.$el.getBoundingClientRect();
      this.last_drag_y = Math.min(
        this.$el.clientHeight - 20,
        Math.max(0, e.clientY - bounds.top)
      );
      const s = Math.min(
        Math.max(this.last_drag_y / this.$el.clientHeight, 0),
        1
      );
      const rs = Math.floor(s * this.num_total_rows);
      this.$emit("scroll", rs);
    },
    dragMove(e) {
      if (!this.dragging) {
        return;
      }
      const bounds = this.$el.getBoundingClientRect();
      this.last_drag_y = Math.min(
        this.$el.clientHeight - 20,
        Math.max(0, e.clientY - bounds.top)
      );
      /*
        const s = Math.min(Math.max(this.last_drag_y / this.$el.clientHeight, 0), 1)
        const rs = Math.floor(s * this.num_total_rows)
        this.$emit('scroll', rs)
        */
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/theme";

$scrollbar-width: 16px;
$scrollbar-thumb-height: 20px;

.scrollbar {
  user-select: none;
  width: $scrollbar-width;
  //background-color: $col-background;
  background-color: $col-divider;
  position: relative;
  border: 1px solid $col-border;
  border-bottom: 0;
  border-top: 0;
}

.thumb {
  position: absolute;
  width: $scrollbar-width - 2;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: $col-sub;
  height: $scrollbar-thumb-height;
  line-height: $scrollbar-thumb-height;
  transition: background-color 280ms ease;
  color: white;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;

  &:hover,
  &.dragging {
    background-color: $col-primary;
  }

  &.dragging {
    cursor: none;
    width: auto;
    padding: 0 4px;
    padding-right: $scrollbar-width + 4px;
  }
}
</style>
