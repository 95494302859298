<template>
  <Modal v-model="is_open" :width="640" :styles="{ top: '48px' }" :title="$t('action.export')" @on-cancel="close">
    <p>
      <span v-html="$t('export.part0', { name: table_name })"></span>
      <Select v-model="settings.rows" size="small" class="inline-text" :disabled="readonly">
        <Option value="all">{{$t('export.rows_all')}}</Option>
        <Option value="erroneous">{{$t('export.rows_erroneous')}}</Option>
        <Option value="correct">{{$t('export.rows_correct')}}</Option>
      </Select>
      <span v-html="$t('export.part1', { rows: table_rows })"></span>
      <Select v-model="settings.columns" size="small" class="inline-text" :disabled="readonly">
        <Option value="all">{{$t('export.columns_all')}}</Option>
        <Option value="visible">{{$t('export.columns_visible')}}</Option>
      </Select>
      <span v-html="$t('export.part2')"></span>
      <Select v-model="settings.column_value" size="small" class="inline-text" :disabled="readonly">
        <Option value="original">{{$t('export.column_value_original')}}</Option>
        <Option value="corrected">{{$t('export.column_value_corrected')}}</Option>
      </Select>
      <span v-html="$t('export.part3')"></span>
    </p>
    <p>
      <span v-html="$t('export.part4')"></span>
    </p>
    <div class="switch-check">
      <i-switch v-model="settings.extra_inverse" :disabled="readonly"></i-switch>
      <div v-html="$t(settings.column_value === 'original' ? 'export.extra_inverse_corrected' : 'export.extra_inverse_original')"></div>
    </div>
    <div class="switch-check">
      <i-switch v-model="settings.extra_error" :disabled="readonly"></i-switch>
      <div v-html="$t('export.extra_error')"></div>
    </div>
    <div class="switch-check">
      <i-switch v-model="settings.extra_action" :disabled="readonly"></i-switch>
      <div v-html="$t('export.extra_action')"></div>
    </div>
    <div class="switch-check">
      <i-switch v-model="settings.extra_duplicates" :disabled="readonly"></i-switch>
      <div v-html="$t('export.extra_duplicates')"></div>
    </div>
    <div class="switch-check">
      <i-switch v-model="settings.extra_comment" :disabled="readonly"></i-switch>
      <div v-html="$t('export.extra_comment')"></div>
    </div>
    <Alert v-if="cant_request" type="warning" show-icon>
      {{$t('hint.export_payment_necessary')}}
      <div>
        <b><a href="mailto:info@24relations.de" target="_blank">info@24relations.de</a></b>
      </div>
    </Alert>
    <div slot="footer">
      <Button @click="close">{{$t('action.cancel')}}</Button>
      <Button v-if="!job" type="primary" icon="ios-code-download" :loading="loading" :disabled="cant_request" @click="onRequest">{{$t('action.request-export')}}</Button>
      <Button v-else type="success" icon="ios-cloud-download-outline" :loading="loading || !job.finished" @click="onDownload">{{$t('action.download')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import moment from 'moment'
  import { saveAs } from 'file-saver'
  import APIMixin from '/mixins/api'
  import MethodIntervalMixin from '/mixins/method-interval'
  import sanitizeFilename from 'sanitize-filename'

  export default {
    mixins: [
      APIMixin,
      MethodIntervalMixin
    ],
    data() {
      return {
        is_open: false,
        settings: {
          rows: 'all',
          columns: 'all',
          column_value: 'corrected',
          extra_error: false,
          extra_action: false,
          extra_inverse: false,
          extra_comment: false
        },
        job: null,
      }
    },
    computed: {
      loading() {
        return this.api_requests.num_pending > 0
      },
      readonly() {
        return !!this.job
      },
      table_name() {
        return this.$store.state.table.name
      },
      table_rows() {
        return this.$store.state.table.rows
      },
      filename() {
        if (!this.job || !this.job.finished) {
          return ''
        }
        return `${moment(this.job.finished).format('YYYYMMDD_HHmm')}_${this.$store.state.table.name}_Export.24myCleanData.xlsx`
      },
      is_paid() {
        const user = this.$store.state.auth.user
        return user && (user.admin || this.$store.state.table.paid)
      },
      cant_request() {
        return (this.settings.column_value === 'corrected' || this.settings.extra_inverse) && !this.is_paid
      }
    },
    watch: {
      'settings.column_value'() {
        this.settings.extra_inverse = false
      },
      is_paid(paid) {
        this.settings.column_value = paid ? this.settings.column_value : 'original'
        this.settings.extra_inverse = (paid || this.settings.column_value === 'corrected') ? this.settings.extra_inverse : false
      }
    },
    methods: {
      open() {
        this.is_open = true
        this.$store.dispatch('table/reload')
        this.methodInterval('refresh', this.onRefresh, 1000)
      },
      close() {
        this.is_open = false
        this.cancelMethodInterval('refresh')
        this.apiCancelAll()
        this.apiGet('cancel', { url: `/export-job/${this.job._id}/cancel` })
        this.job = null
      },
      onRefresh() {
        if (!this.is_open || !this.job || this.job.finished) {
          return
        }
        this.apiGet('export', { url: `/export-job/${this.job._id}` })
          .then((job) => {
            if (!this.is_open) {
              return
            }
            this.job = job
          }, (err) => {
            this.$reportError(err)
            this.job = null
          })
      },
      onRequest() {
        const data = {
          table: this.$store.state.table._id,
          settings: this.settings
        }
        this.apiPost('export', { url: '/export-job', data })
          .then((job) => {
            if (!this.is_open) {
              return
            }
            this.job = job
          }, (err) => {
            this.$reportError(err)
            this.job = null
          })
      },
      onDownload() {
        this.apiGet('download', {
          url: `/export-job/${this.job._id}/download`,
          responseType: 'blob'
        }).then((res) => {
          saveAs(res, sanitizeFilename(this.filename))
          this.close()
        }, (err) => {
          this.$reportError(err)
          this.close()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  p {
    line-height: 240%;
  }

  .switch-check {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .ivu-switch {
      margin-right: 1rem;
    }
  }
</style>

