import 'core-js/stable'
import 'regenerator-runtime/runtime'

//-----------------------------------------------------------------------------

require('iview/dist/styles/iview.css')
require('./styles/index.scss')

//-----------------------------------------------------------------------------

const PRODUCTION = process.env.NODE_ENV === 'production'
//global.PROFILING = !PRODUCTION
global.PROFILING = true

//-----------------------------------------------------------------------------

import _ from 'lodash'
import Bowser from 'bowser'

const SUPPORTED_BROWSERS = [
  'Chrome',
  'Chromium',
  'Firefox',
  'Safari'
  //'Microsoft Edge'
]
const detected = Bowser.parse(window.navigator.userAgent)
//console.log(browser)

if (detected.platform.type !== 'desktop') {
  alert('24myCleanData unterstützt keine Mobilgeräte.')
  throw new Error('incompatible-browser')
}
if (!_.includes(SUPPORTED_BROWSERS, detected.browser.name)) {
  alert('24myCleanData unterstützt Ihren Browser nicht.')
  throw new Error('incompatible-browser')
}

//-----------------------------------------------------------------------------

import Vue from 'vue'

Vue.config.debug = !PRODUCTION
Vue.config.devtools = false
Vue.config.productionTip = false

//-----------------------------------------------------------------------------

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'de-DE',
  messages: {
    'de-DE': require('/../shared/strings/de-DE.json')
  }
})

//-----------------------------------------------------------------------------

import iView from 'iview'
import iView_locale from 'iview/dist/locale/de-DE'

Vue.use(iView, {
  transfer: true,
  locale: iView_locale
})

//-----------------------------------------------------------------------------

import EventBus from './plugins/event-bus'
Vue.use(EventBus)

//-----------------------------------------------------------------------------

Vue.prototype.$reportError = function(err) {
  this.$Notice.error({
    title: this.$t('label.error'),
    desc: err,
    duration: 0
  })
}

//-----------------------------------------------------------------------------

import api_instance from './services/api'
const api = Vue.prototype.$api = api_instance

import store from './store'

const updateLoading = () => store.commit('setLoading', api.hasOpenRequests)
api.on('request-started', updateLoading)
api.on('request-successful', updateLoading)
api.on('request-failed', updateLoading)

store.dispatch('auth/refresh')
  .then(() => {
    if (store.state.table._last_id && !store.state.table._id) {
      store.dispatch('table/load', store.state.table._last_id)
    }
  })

store.subscribe((mutation, state) => {
  if (mutation.type === 'auth/setUser') {
    if (state.auth.user) {
      store.dispatch('configurations/refresh')
    } else {
      store.commit('table/reset')
      store.commit('selection/reset')
      store.commit('configurations/reset')
    }
  }
})

//-----------------------------------------------------------------------------

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import ViewLogin from '/views/login'
import ViewResetPwd from '/views/reset-pwd'
import ViewTable from './views/table/index'
import ViewImprint from './views/imprint'
import ViewPrivacy from './views/privacy'

const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'login', path: '/login', component: ViewLogin, meta: { public: true } },
    { name: 'reset-pwd', path: '/reset-pwd', component: ViewResetPwd, meta: { public: true } },
    { name: 'imprint', path: '/imprint', component: ViewImprint, meta: { public: true, overflow: true } },
    { name: 'privacy', path: '/privacy', component: ViewPrivacy, meta: { public: true, overflow: true } },
    { name: 'table', path: '/table', component: ViewTable },
    { path: '/', redirect: '/table' },
    { path: '*', redirect: '/' }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(function(to, from, next) {
  if (to.meta.public) {
    return next()
  }
  store.dispatch('auth/refresh')
    .then(() => next())
    .catch(() => {
      next(to.meta.public ? undefined : { name: 'login', query: { redirect: encodeURIComponent(to.fullPath) } })
    })
})

//-----------------------------------------------------------------------------

import Tip from '/components/tip'
Vue.component('Tip', Tip)

//-----------------------------------------------------------------------------

import App from './app'

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
