<template>
  <div :class="[ '_reference-select', { missing } ]">
    <div class="label">
      {{label}}:<tip :text="$t('hint.reference_columns_necessary', { header })"></tip>
    </div>
    <template v-if="plain">
      <select v-model="value" :placeholder="$t('placeholder.select_column')" size="small" clearable>
        <option v-for="h in available_header" :key="h" :value="h">{{h}}</option>
      </select>
    </template>
    <template v-else>
      <Select v-model="value" :transfer="false" :prefix="prefix" :placeholder="$t('placeholder.select_column')" size="small" clearable>
        <Option v-for="h in available_header" :key="h" :value="h">{{h}}</Option>
      </Select>
    </template>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      header: {
        type: String,
        required: true
      },
      reference: {
        type: String,
        required: true
      },
      plain: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      label() {
        const k = `header-references.${this.reference}`
        return this.$te(k) ? this.$t(k) : this.reference
      },
      missing() {
        const info = this.value && this.$store.getters['table/headerInfos'][this.value]
        return !info || info.hidden
      },
      prefix() {
        return this.missing ? 'ios-alert' : ''
      },
      available_header() {
        const header = _.reject(this.$store.state.table.header, (h) => this.$store.getters['table/headerInfos'][h].hidden)
        header.sort((a, b) => {
          return a.localeCompare(b, 'de', { numeric: true })
        })
        return header
      },
      value: {
        get() {
          const settings = this.$store.state.table.settings[this.header]
          return (settings && settings.references && settings.references[this.reference]) || ''
        },
        set(v) {
          this.$store.commit('table/setHeaderReference', {
            header: this.header,
            reference: this.reference,
            value: v || ''
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '/styles/theme';

  ._reference-select {
    margin-bottom: 4px;

    select {
      width: 100%;
      background-color: #fff;
    }

    &.missing {
      .ivu-select-selection, select {
        border-color: $col-error;
      }

      .ivu-select-prefix {
        color: $col-error;
        font-size: 18px;
        line-height: 1;
      }
    }

    .label {
      font-size: 11px;
      margin-bottom: 3px;
    }
  }
</style>
