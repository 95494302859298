<template>
  <div class="app">
    <MenuBar v-if="user"></MenuBar>
    <div :class="[ 'view-wrap', { padded, overflow }]">
      <RouterView ></RouterView>
    </div>

    <DialogOpenTable ref="dlg_open_table"></DialogOpenTable>
    <DialogStatistics ref="dlg_statistics"></DialogStatistics>
    <DialogComment ref="dlg_comment"></DialogComment>
    <DialogReport ref="dlg_report"></DialogReport>
    <DialogExport ref="dlg_export"></DialogExport>
    <DialogUsers ref="dlg_users"></DialogUsers>
    <DialogPayments ref="dlg_payments"></DialogPayments>
    <DialogMappingRequest ref="dlg_mapping_request"></DialogMappingRequest>
    <DialogImportWizard ref="dlg_import_wizard"></DialogImportWizard>
    <DialogAssociations ref="dlg_associations"></DialogAssociations>
    <DialogImport ref="dlg_import"></DialogImport>
  </div>
</template>

<script>
  import MenuBar from './menu-bar'
  import DialogOpenTable from './components/dialog-open-table'
  import DialogStatistics from './components/dialog-statistics'
  import DialogComment from './components/dialog-comment'
  import DialogReport from './components/dialog-report'
  import DialogExport from './components/dialog-export'
  import DialogMappingRequest from './components/dialog-mapping-request'
  import DialogUsers from './components/dialog-users'
  import DialogPayments from './components/dialog-payments'
  import DialogImportWizard from './components/dialog-import-wizard'
  import DialogAssociations from './components/dialog-associations'
  import DialogImport from './components/dialog-import'

  export default {
    components: {
      MenuBar,
      DialogOpenTable,
      DialogStatistics,
      DialogComment,
      DialogReport,
      DialogExport,
      DialogMappingRequest,
      DialogUsers,
      DialogPayments,
      DialogImportWizard,
      DialogAssociations,
      DialogImport
    },
    computed: {
      overflow() {
        return !!this.$route.meta.overflow
      },
      padded() {
        const r = this.$route.name
        return r !== 'table' && r !== 'login'
      },
      user() {
        return this.$store.state.auth.user
      }
    },
    created() {
      this.$bus.$on('open-table', () => this.$refs.dlg_open_table.open())
      this.$bus.$on('open-statistics', () => this.$refs.dlg_statistics.open())
      this.$bus.$on('open-comment', () => this.$refs.dlg_comment.open())
      this.$bus.$on('open-report', () => this.$refs.dlg_report.open())
      this.$bus.$on('open-export', () => this.$refs.dlg_export.open())
      this.$bus.$on('open-users', () => this.$refs.dlg_users.open())
      this.$bus.$on('open-payments', () => this.$refs.dlg_payments.open())
      this.$bus.$on('open-import-wizard', () => this.$refs.dlg_import_wizard.open())
      this.$bus.$on('mapping-request', (report, request) => this.$refs.dlg_mapping_request.open(report, request))
      this.$bus.$on('open-associations', () => this.$refs.dlg_associations.open())
      this.$bus.$on('open-import', () => this.$refs.dlg_import.open())
    }
  }
</script>

<style lang="scss" scoped>
  .app {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .view-wrap {
    flex: 1;
    overflow-y: hidden;

    &.padded {
      padding: 1rem 1rem;
    }

    &.overflow {
      overflow-y: auto;
    }
  }
</style>
