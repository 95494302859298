<template>
  <Form :label-width="120" @submit.native.prevent>
    <FormItem :label="$t('label.format')">
      <Select v-model="transform.format" :disabled="readonly">
        <Option v-for="f in options.input_formats" :key="f" :value="f">{{translateDateFormat(f)}}</Option>
      </Select>
    </FormItem>

    <FormItem v-if="has_adjust_short_years" :label="$t('label.adjust_short_years')">
      <i-switch v-model="transform.adjust_short_years" :disabled="readonly"></i-switch>
      <p class="hint">{{ $t('hint.interpret_short_years') }}</p>
    </FormItem>

    <FormItem :label="$t('action.convert')">
      <i-switch v-model="transform.convert" :disabled="readonly"></i-switch>
    </FormItem>

    <FormItem :label="$t('label.convert_to')">
      <Select v-model="transform.convert_format" :disabled="!transform.convert || readonly">
        <Option v-for="f in options.output_formats" :key="f" :value="f">{{f}}</Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'options', 'readonly' ],
    computed: {
      has_adjust_short_years() {
        return /\(YY\)YY/g.test(this.transform)
      }
    },
    methods: {
      translateDateFormat(f) {
        const key = `date-format.${f}`
        return this.$i18n.te(key) ? this.$i18n.t(key) : f
      }
    }
  }
</script>
