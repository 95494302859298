<template>
  <Modal v-model="is_open" :width="640" :title="$t('label.mapping_requested')" @on-cancel="close">
    <div v-if="report">
      <template v-if="list_entries.length">
        <div class="title" v-html="$t('mapping-request.mapping_of_values_from_cells', { rows: report.num_rows})"></div>
        <List :data="list_entries" :columns="list_columns" class="entries-list">
          <span slot="value" slot-scope="{ item }">{{item.value || empty_value}}</span>
          <span slot="replacement">{{report.value || empty_value}}</span>
          <span slot="action" slot-scope="{ item }">{{$t(`mapping-request.mapping_${item.action}`)}}</span>
        </List>
      </template>
      <Alert v-if="report.enum_added" type="warning">
        <span v-html="$t('mapping-request.value_added_to_enum', { value: report.value })"></span>
      </Alert>
      <Alert v-if="report.enum_added_vehicle_brand" type="warning">
        <span v-html="$t('mapping-request.value_added_to_vehicle_brands', { value: report.value })"></span>
      </Alert>
      <Alert v-if="report.enum_added_vehicle_brand_model" type="warning">
        <span v-html="$t('mapping-request.value_added_to_vehicle_brand_models')"></span>
        <ul style="font-weight:bold;">
          <li v-for="(e, ei) of report.enum_added_vehicle_brand_model" :key="ei">
            {{ e.a_value }} / {{ e.b_value }}
          </li>
        </ul>
      </Alert>
    </div>
    <div slot="footer">
      <Button @click="close">{{$t('action.close')}}</Button>
      <Button type="error" :disabled="loading" @click="perform">{{$t('action.perform-mapping')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import APIMixin from '/mixins/api'

  export default {
    components: {
      List
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        is_open: false,
        open_time: 0,
        report: null,
        request: null,
        loading: false
      }
    },
    computed: {
      list_entries() {
        const res = []
        for (const k in this.report.existing) {
          res.push({ value: k, action: 'existed' })
        }
        for (const k in this.report.mapped) {
          res.push({ value: k, action: 'added' })
        }
        return res
      },
      empty_value() {
        return this.$t('label.empty-value-indicator')
      },
      list_columns() {
        return _.compact([
          {
            key: 'value',
            filter: true,
            sortable: true,
            label: this.$t('label.map_from')
          },
          {
            slot: 'replacement',
            label: this.$t('label.map_to')
          },
          {
            key: 'action',
            sortable: true,
            label: this.$t('label.result')
          }
        ])
      }
    },
    mounted() {
      document.addEventListener('keyup', this.onKey)
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.onKey)
    },
    methods: {
      async open(report, request) {
        this.is_open = true
        this.open_time = Date.now()
        this.report = report
        this.request = request
        this.loading = false
      },
      close() {
        this.is_open = false
        this.open_time = 0
      },
      async perform() {
        this.loading = true
        const report = await this.apiPost('map', {
          url: `/table/${this.$store.state.table._id}/mapping`,
          data: this.request
        })
        this.loading = false
        this.close()
        this.$bus.$emit('mapping-performed', report, this.request)
        if (_.size(this.$store.state.selection.rows) === 1) {
          this.$store.commit('selection/reset')
        }
      },
      onKey(e) {
        if (!this.is_open || this.loading) {
          return
        }
        if (Date.now() - this.open_time < 500) {
          return
        }
        if (e.key === 'Enter') {
          this.perform()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .title {
    font-size: 1.16em;
    margin-bottom: 0.5em;
  }

  .entries-list {
    height: 250px;
    margin-bottom: 1rem;
  }
</style>
