<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :title="$t('label.associations')" @on-cancel="close">
    <div v-if="is_open">
      <Form :label-width="160" @submit.native.prevent>
        <FormItem :label="$t('label.list')">
          <Select v-model="type">
            <Option value="vehicle-brand-model">{{$t('associations.vehicle-brand-model')}}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('label.export')">
          <Button @click="exportList" :disabled="loading">
            {{ $t('action.export') }}
          </Button>
        </FormItem>
        <FormItem :label="$t('label.import')">
          <Button @click="$refs.import_file.click()" :disabled="loading">
            {{ $t('action.import') }}
          </Button>
          <input ref="import_file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:none;" @change="importList"/>
          <p v-if="type === 'vehicle-brand-model'">
              <b>Importformat:</b>
              <ul>
                <li>die Tabelle wird mit den Spalten "Marke" und "Modell" erwartet</li>
                <li>die Tabelle ersetzt bestehende Marke/Modell Bezüge komplett. D.h. kommen sie nicht in der Importtabelle vor, werden sie im System gelöscht</li>
              </ul>
          </p>
        </FormItem>
        <FormItem v-if="result || loading" :label="$t('label.result')">
          <Spin v-if="loading" size="large"></Spin>
          <table v-if="result" class="results">
            <tr>
              <td>Ignoriert (Unvollständig)</td>
              <td>{{ result.dropped }}</td>
            </tr>
            <tr>
              <td>Duplikate</td>
              <td>{{ result.duplicates }}</td>
            </tr>
            <tr>
              <td>Angelegt</td>
              <td>{{ result.created }}</td>
            </tr>
          </table>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button @click="close" :disabled="loading">{{$t('action.close')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'
  import moment from 'moment'
  import { saveAs } from 'file-saver'
  import sanitizeFilename from 'sanitize-filename'

  export default {
    mixins: [
      APIMixin
    ],
    data() {
      return {
        is_open: false,
        loading: false,
        result: null,
        type: 'vehicle-brand-model'
      }
    },
    methods: {
      open() {
        this.is_open = true
        this.loading = false
      },
      close() {
        this.is_open = false
        this.apiCancelAll()
      },
      async exportList() {
        this.loading
        const res = await this.apiGet('export', {
          url: `/associations/${this.type}/export`,
          responseType: 'blob'
        })
        const fn = `${moment().format('DDMMYYYY_HHmmss')}_Cleandata-Zuordnung_MarkeModell.xlsx`;
        saveAs(res, sanitizeFilename(fn));
      },
      async importList(e) {
        const file = _.get(e, 'target.files[0]')
        const fn = (file && file.name) || ''
        const ext = _.toLower(fn).split('.').pop()
        if (ext !== 'xlsx') {
          return
        }
        this.loading = true
        const data = new FormData()
        data.append('file', file)
        e.target.value = ''
        this.result = await this.apiPost('import', { url: `/associations/${this.type}/import`, data });
        this.loading = false
        this.$bus.$emit('table-request-refresh')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .results {
    td:last-child {
      padding-left: 1em;
    }
  }
</style>
