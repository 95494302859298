<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem v-if="is_admin">
      <Button long @click="$bus.$emit('open-associations')">
        <Icon type="ios-clipboard-outline"></Icon>
        &nbsp;Administration/Zuordnungen öffnen
      </Button>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'config', 'transform', 'index', 'readonly' ],
    computed: {
      is_admin() {
        const user = this.$store.state.auth.user
        return user && user.admin
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';
</style>
