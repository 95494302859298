<template>
  <Modal v-model="is_open" :width="480" :title="$t('action.comment')" @on-cancel="close">
    <p class="intro" v-html="$t('selection.comment_description', { length: selection.rows.length, header: selection.header})"></p>
    <div v-if="multiple_comments" class="multiple-comments">
      <p>{{$t('selection.existing_comments')}}</p>
      <Tag v-for="(ci, ck) in comments" :key="ck" type="border" @click.native="text = ck">{{ck}}</Tag>
    </div>
    <Input v-model="text" :disabled="loading" :placeholder="$t('placeholder.comment')"></Input>
    <div slot="footer">
      <Button @click="close">{{$t('action.cancel')}}</Button>
      <Button type="primary" :loading="loading" icon="ios-pricetag-outline" @click="onComment">{{$t('action.comment')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    data() {
      return {
        loading: false,
        is_open: false,
        comments: null,
        text: ''
      }
    },
    computed: {
      multiple_comments() {
        return _.size(_.keys(this.comments)) > 1
      },
      selection() {
        return this.$store.state.selection
      }
    },
    methods: {
      async open() {
        this.is_open = true
        this.loading = true
        this.text = ''
        const selection = this.$store.state.selection
        const data = {
          header: selection.header,
          indices: selection.rows
        }
        this.apiPost({ url: `/table/${this.$store.state.table._id}/comments`, data })
          .then((res) => {
            this.comments = res
            this.loading = false
            let highest = null
            for (const c in this.comments) {
              if (!highest || _.size(this.comments[c]) >= _.size(this.comments[highest])) {
                highest = c
              }
            }
            this.text = highest || ''
          }, () => {
            this.loading = false
          })
      },
      onComment() {
        this.is_open = false
        const selection = this.$store.state.selection
        const data = {
          header: selection.header,
          indices: selection.rows,
          text: this.text
        }
        this.apiPost({ url: `/table/${this.$store.state.table._id}/comment`, data })
          .then((res) => {
            this.comments = res
            this.is_open = false
            this.loading = false
            this.text = ''
            this.$bus.$emit('table-request-refresh')
          }, (err) => {
            this.$reportError(err)
            this.loading = false
          })
      },
      close() {
        this.is_open = false
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .intro {
    font-size: 1.16em;
    margin-bottom: 1rem;
  }

  .multiple-comments {
    margin-bottom: 1rem;
  }
</style>
