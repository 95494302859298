<template>
  <div>
    <Button type="primary" icon="ios-arrow-back" ghost style="margin-bottom:1rem;" @click="$router.go(-1)">{{$t('action.back')}}</Button>
    <div v-html="content"></div>
  </div>
</template>

<script>
  import Content from '../assets/imprint/de-DE.html'

  export default {
    computed: {
      content() {
        return Content
      }
    }
  }
</script>
