<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :z-index="6000" :title="title" @on-cancel="cancel">
    <input ref="file_input" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:none;" @change="doUpload">

    <Spin v-if="loading" size="large"></Spin>

    <Tabs v-if="is_open && setup" v-model="current_tab">
      <TabPane :label="$t('import.preprocessing')" name="preprocess">
        <table>
          <tr
            v-for="(p, pk) of setup.preprocess"
            :key="pk"
          >
            <td>{{pk}}</td>
            <td>
              <select v-model="setup.preprocess[pk]" :transfer="false">
                <option :value="''">&ndash;</option>
                <option v-for="o of preprocessors" :key="o" :value="o">{{ $t(`import.preprocessor.${o}`) }}</option>
              </select>
            </td>
          </tr>
        </table>
      </TabPane>
    </Tabs>
    <div slot="footer">
      <Button @click="cancel">{{$t('action.cancel')}}</Button>
      <Button type="primary" :loading="loading" :disabled="!can_apply" @click="doImport">{{$t('action.import')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    data() {
      return {
        current_tab: 'preprocess',
        is_open: false,
        loading: false,
        setup: null,
        backup: {}
      }
    },
    computed: {
      title() {
        let title = this.$t('label.import')
        return this.setup ? `${title}: ${this.setup.filename}` : title
      },
      can_apply() {
        return !this.loading
      },
      preprocessors() {
        return [
          'remove-vehicle-brand-name'
        ]
      }
    },
    methods: {
      open() {
        this.is_open = true
        this.setup = null
        this.$refs.file_input.value = ''
        this.$refs.file_input.click()
      },
      close() {
        this.is_open = false
        this.setup = null
        this.apiCancelAll()
      },
      cancel() {
        this.close()
      },
      async doImport() {
        const e = this.$refs.file_input
        const file = _.get(e, 'files[0]')
        const fn = (file && file.name) || ''
        const ext = _.toLower(fn).split('.').pop()
        if (ext !== 'xlsx') {
          return
        }
        this.loading = true
        await this.$store.dispatch('table/import', { file, setup: this.setup })
        this.loading = false
        this.close()
      },
      async doUpload(e) {
        const tid = this.$store.state.table._id
        const file = _.get(e, 'target.files[0]')
        const fn = (file && file.name) || ''
        const ext = _.toLower(fn).split('.').pop()
        if (ext !== 'xlsx') {
          return
        }
        const data = new FormData()
        data.append('file', file)

        this.loading = true
        const result = await this.apiPost('pre', { url: `/table/${tid}/pre-import`, data });
        this.setup = {
          filename: result.filename,
          preprocess: _.zipObject(result.header, _.map(result.header, _.constant('')))
        }
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  table {
    td:first-child {
      padding-right: 1em;
    }
  }
</style>
