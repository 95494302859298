<template>
  <div class="list">
    <div v-if="has_filter" class="filter-row">
      <div>{{ $t('label.n_of_t_entries', { count: filtered_items.length, total: all_items.length }) }}</div>
      <div class="input">
        <Input :value="filter_text" :placeholder="$t('placeholder.searchtext')" search :enter-button="$t('action.filter')" size="small" @on-search="setFilterText"></Input>
      </div>
      <div v-if="filter_text" class="clear">
        <Button icon="ios-close" size="small" @click="reset"></Button>
      </div>
      <div class="select">
        <Select v-model="filter_mode" size="small">
          <Option value="exact">Exakt</Option>
          <Option value="fuzzy">Fuzzy</Option>
        </Select>
      </div>
      <div>
        <slot name="list-actions"></slot>
      </div>
    </div>
    <div v-else>
      <div>{{ $t('label.n_entries', { count: all_items.length }) }}</div>
    </div>
    <div class="header">
      <table>
        <th v-for="(col, coli) in columns" :key="coli" ref="header" :style="headerStyle(col)">
          <div class="inner">
            <span class="label" :title="col.label_tip || ''">
              <span>{{col.label || '&nbsp;'}}</span>
            </span>
            <span v-if="col.sortable">
              <span class="sort-btn" @click="setSort(col, 1)">
                <Icon type="ios-arrow-dropup"></Icon>
              </span>
              <span class="sort-btn" @click="setSort(col, -1)">
                <Icon type="ios-arrow-dropdown"></Icon>
              </span>
            </span>
          </div>
        </th>
      </table>
    </div>
    <div class="items">
      <table>
        <colgroup>
          <col v-for="(col, coli) in columns" :key="coli" :style="`width:${column_widths[coli] ? column_widths[coli]+'px' : 'auto'};`"></col>
        </colgroup>
        <tr v-for="item in displayed_items" :key="item.index">
          <td v-for="(col, coli) in columns" :key="coli" :style="cellStyle(col, item)" @dblclick="onDblClick">
            <slot :name="col.slot || col.key || `_col${coli}`" :item-index="item.index" :item="item.value">
              {{defaultValue(item, col)}}
            </slot>
          </td>
        </tr>
      </table>
      <infinite-loading :key="infinite_id" @infinite="infiniteHandler">
        <div slot="spinner"></div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import InfiniteLoading from 'vue-infinite-loading';
  import Fuse from 'fuse.js'

  export default {
    components: {
      InfiniteLoading
    },
    props: {
      data: {
        type: [ Object, Array ],
        required: true
      },
      columns: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        sortkey: '',
        sortdir: 1,
        infinite_id: 0,
        step_size: 100,
        filter_text: '',
        filtered_items: [],
        displayed_items: [],
        column_widths: {},
        filter_mode: 'exact'
      }
    },
    computed: {
      is_array() {
        return _.isArray(this.data)
      },
      filter_keys() {
        return _.uniq(_.compact(_.flatten(_.map(this.columns, (c) => {
          return (c.filter && c.key) || c.filter
        }))))
      },
      has_filter() {
        return !_.isEmpty(this.filter_keys)
      },
      all_items() {
        return _.map(this.data, (v, k) => {
          return {
            index: k,
            value: v
          }
        })
      }
    },
    watch: {
      all_items() {
        this.rebuild()
      },
      filter_text() {
        this.rebuild()
      },
      filter_mode() {
        this.rebuild()
      }
    },
    created() {
      this.rebuild()
    },
    mounted() {
      this.updateColumns()
    },
    methods: {
      updateColumns() {
        const header = this.$refs.header
        this.column_widths = _.map(this.columns, (c, ci) => {
          return (header && header[ci] && header[ci].clientWidth) || 0
        })
      },
      headerStyle(col) {
        let width = 'auto'
        if (_.isFinite(col.width)) {
          width = `${col.width}px`
        } else if (_.isString(col.width)) {
          width = col.width
        }
        return {
          width,
          'text-align': col.align || 'left'
        }
      },
      cellStyle(col) {
        return col.align ? `text-align:${col.align};` : undefined
      },
      setFilterText(txt) {
        this.filter_text = txt
      },
      setSort(col, dir) {
        if (!col.sortable) {
          return
        }
        this.sortkey = col.key
        this.sortdir = dir
        this.rebuild()
      },
      rebuild() {
        if (this.has_filter && this.filter_text) {

          const txt = _.escapeRegExp(this.filter_text)

          if (this.filter_mode === 'exact') {
            this.filtered_items = _.filter(this.all_items, (i) => {
              return _.some(this.filter_keys, (k) => {
                const re = new RegExp(txt, 'gi')
                if (this.is_array) {
                  return re.test(_.isObject(i.value) ? i.value[k] : i.value)
                } else {
                  return (k === 'KEY' && re.test(i.index)) || (k === 'VALUE' && re.test(i.value))
                }
              })
            })
          } else if (this.filter_mode === 'fuzzy') {
            const fuse = new Fuse(this.all_items, {
              shouldSort: false,
              threshold: 1.0,
              keys: _.map(this.filter_keys, (k) => `value.${k}`)
            })
            const res = fuse.search(txt)
            this.filtered_items = _.map(res, 'item')
          }

        } else {
          this.filtered_items = _.clone(this.all_items)
        }
        if (this.sortkey) {
          const sortkey = this.sortkey || null
          const sortdir = this.sortdir || 1
          this.filtered_items.sort((a, b) => {
            a = _.toString((this.is_array && sortkey) ? a.value[sortkey] : a.value)
            b = _.toString((this.is_array && sortkey) ? b.value[sortkey] : b.value)
            return a.localeCompare(b, 'de', { numeric: true }) * sortdir
          })
        }

        this.infinite_id += 1
        this.displayed_items = []
        this.displayMoreItems()
        this.$nextTick(this.updateColumns);
      },
      displayMoreItems() {
        const s = this.filtered_items.slice(this.displayed_items.length, this.displayed_items.length + this.step_size)
        this.displayed_items.push(...s)
        return s.length
      },
      infiniteHandler($state) {
        const count = this.displayMoreItems()
        $state[count ? 'loaded' : 'complete']()
      },
      defaultValue(item, column) {
        if (column.key) {
          return _.isObject(item.value) ? item.value[column.key] : item.value
        }
      },
      reset() {
        this.filter_text = ''
        this.rebuild()
      },
      onDblClick() {
        this.$emit("dblclick-item")
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .list {
    display: flex;
    flex-direction: column;
  }

  .filter-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    .input {
      margin-left: 1em;
      flex: 1;
    }

    .select {
      padding-top: 2px;
      margin-left: 1em;
    }

    button {
      margin-left: 0.5em;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th, td {
      padding: 0.15em 0.25em;
      line-height: 140%;
      //border: 1px solid yellow;
    }

    th, td {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    tr {
      &:nth-child(odd) td {
        background-color: $col-background;
      }
    }
  }

  .header {
    font-weight: bold;
    background-color: $col-divider;
    border: 1px solid $col-border;
    border-bottom: 0;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;

    th > .inner {
      display: flex;
      white-space: nowrap;
    }

    .label {
      cursor: default;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.25em;
    }
  }

  .sort-btn {
    color: #000;
    cursor: pointer;
    transition: color 180ms ease;

    &:hover {
      color: $col-primary;
    }
  }

  .items {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid $col-border;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }

  .action-btn {
    display: inline-block;
    cursor: pointer;
    transition: color 280ms ease;

    &:hover {
      color: $col-primary;
    }

    &.danger:hover {
      color: $col-error;
    }

    .ivu-icon {
      font-size: 16px;
      line-height: 1;
    }
  }
</style>
