<template>
  <Modal v-model="is_open" :width="480" :title="$t('action.report')" @on-cancel="close">
    <p class="intro" v-html="$t('selection.report_description', { length: selection.rows.length, header: selection.header})"></p>
    <Input v-model="text" type="textarea" :disabled="loading" :placeholder="$t('placeholder.report')"></Input>
    <div slot="footer">
      <Button @click="close">{{$t('action.cancel')}}</Button>
      <Button type="primary" :loading="loading" icon="ios-send-outline" :disabled="!can_report" @click="onReport">{{$t('action.report')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    data() {
      return {
        loading: false,
        is_open: false,
        text: ''
      }
    },
    computed: {
      multiple_comments() {
        return _.size(_.keys(this.comments)) > 1
      },
      selection() {
        return this.$store.state.selection
      },
      can_report() {
        return this.selection.rows.length > 0 && !_.isEmpty(_.trim(this.text))
      }
    },
    methods: {
      async open() {
        this.is_open = true
        this.text = ''
      },
      onReport() {
        this.is_open = false
        const data = {
          header: this.selection.header,
          indices: this.selection.rows,
          text: this.text
        }
        this.apiPost({ url: `/table/${this.$store.state.table._id}/report`, data })
          .then(() => {
            this.is_open = false
            this.loading = false
            this.text = ''
          }, (err) => {
            this.$reportError(err)
            this.loading = false
          })
      },
      close() {
        this.is_open = false
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .intro {
    font-size: 1.16em;
    margin-bottom: 1rem;
  }
</style>
