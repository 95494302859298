<template>
  <div class="tip">
    <Poptip trigger="hover" :title="rendered_title" :width="300" :word-wrap="true" :placement="placement">
      <Icon type="ios-help-circle-outline"></Icon>
      <div slot="content" style="text-align:left;" v-html="text"></div>
    </Poptip>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        required: true
      },
      placement: {
        type: String,
        default: 'bottom'
      }
    },
    computed: {
      rendered_title() {
        return this.title || this.$t('label.tip')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .tip {
    display: inline-block;
    cursor: pointer;
    padding: 0 0.2em;
    transition: color 280ms ease;

    &:hover {
      color: $col-primary;
    }

    .ivu-icon {
      font-size: 1.3em;
      line-height: 1;
    }
  }
</style>
