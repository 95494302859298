<template>
  <Form :label-width="150" @submit.native.prevent>
    <FormItem :label="$t('label.km_per_day')">
      <InputNumber v-model="transform.km_per_day" :min="0" :disabled="readonly"></InputNumber>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly' ]
  }
</script>
