<template>
  <div class="anchor">
    <div class="wrap">
      <div :class="[ 'suggestions', { up, filled } ]" @click.prevent.stop @mousewheel.stop>
        <div v-for="(i, ii) in items" :key="ii" class="value" @click.prevent.stop="select(i)" v-html="i.label ? i.label : (i.value ? i.value : i)"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: [ 'items', 'up' ],
    computed: {
      filled() {
        return _.size(this.items) >= 1
      }
    },
    methods: {
      select(item) {
        this.$emit('select', item.value ? item.value : item)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .anchor {
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: $z-cell-suggestions;
  }

  .wrap {
    position: relative
  }

  .suggestions {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 240px;
    max-width: 400px;
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $col-content;
    color: white;
    cursor: default;

    &.filled {
      min-height: 100px;
      box-shadow: 2px 2px 8px 3px rgba($col-content, 0.5);
    }

    &.up {
      top: auto;
      bottom: -23px;
    }
  }

  .value {
    line-height: 140%;
    padding: 4px 6px;
    transition: color 280ms ease, background-color 280ms ease;
    cursor: pointer;
    //white-space: nowrap;

    &:hover {
      color: $col-primary;
      background-color: white;
    }
  }
</style>
