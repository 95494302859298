<template>
  <div class="menu-bar">
    <div class="inner">
      <div class="main">
        <Dropdown v-if="is_table" trigger="hover" @on-click="onTable">
          <span class="btn">
            <Icon type="ios-grid" :size="20"></Icon>
            &nbsp;
            {{$t('label.table')}}<span v-if="has_table" class="fg-primary">: <b>{{$store.state.table.name}}</b></span>
          </span>
          <DropdownMenu slot="list">
            <DropdownItem name="open">
              <Icon type="ios-folder-open-outline"></Icon>
              {{$t('action.open')}} / {{$t('action.create')}}
            </DropdownItem>
            <DropdownItem v-if="has_table && is_table && !table.importing" name="import" divided>
              <Icon type="ios-cloud-upload-outline"></Icon>
              {{$t('action.import')}}
            </DropdownItem>
            <DropdownItem v-if="has_table && table_is_ready && !is_empty" name="import-wizard">
              <Icon type="ios-flower-outline"></Icon>
              {{$t('label.import_wizard')}}
            </DropdownItem>
            <DropdownItem v-if="has_table && table_is_ready && !is_empty" name="export">
              <Icon type="ios-cloud-download-outline"></Icon>
              {{$t('action.export')}}
            </DropdownItem>
            <DropdownItem v-if="has_table && table_is_ready && !is_empty" name="statistics">
              <Icon type="ios-pie-outline"></Icon>
              {{$t('label.statistics')}}
            </DropdownItem>
            <DropdownItem v-if="has_table && is_table && !table.importing" name="delete">
              <Icon type="ios-trash-outline"></Icon>
              {{$t('action.delete')}}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <div v-if="is_table && has_table" class="toggle-hidden">
          <div>{{$t('label.hidden_columns')}}</div>
          <i-switch v-model="show_hidden_columns" size="large" :disabled="!table_is_ready">
            <span slot="open">{{$t('label.on')}}</span>
            <span slot="close">{{$t('label.off')}}</span>
          </i-switch>
        </div>

        <div v-if="is_table && has_table" class="toggle-correcting">
          <div>{{$t('label.correct_data')}}</div>
          <i-switch v-model="correct_data" size="large" :disabled="!table_is_ready">
            <span slot="open">{{$t('label.yes')}}</span>
            <span slot="close">{{$t('label.no')}}</span>
          </i-switch>
        </div>

        <div v-if="is_table && has_table && !selection_is_empty" :class="[ 'selection', { primary: !selection.focused, focused: selection.focused } ]">
          <Icon type="ios-crop-outline" :size="20"></Icon>
          &nbsp;
          <span v-html="$t(selection.focused ? 'selection.description_focused' : 'selection.description', { length: selection.rows.length, header: selection.header})"></span>
          &nbsp;
          &nbsp;
          <ButtonGroup size="small">
            <Button @click="onSelection('cancel')" size="small">
              <!--<Icon type="ios-close-circle-outline"></Icon>-->
              {{$t('action.revoke')}}
            </Button>
            <Button @click="onSelection('focus')" size="small">
              <!--<Icon :type="selection.focused ? 'ios-unlock-outline' : 'ios-lock-outline'"></Icon>-->
              {{$t(selection.focused ? 'action.unlock' : 'action.lock')}}
            </Button>
            <Button @click="onSelection('comment')" size="small">
              <!--<Icon type="ios-pricetag-outline"></Icon>-->
              {{$t('action.comment')}}
            </Button>
            <Button v-if="can_report" @click="onSelection('report')" size="small">
              <!--<Icon type="ios-send-outline"></Icon>-->
              {{$t('action.report')}}
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div v-if="user && user.admin">
        <Dropdown trigger="click" @on-click="onAdmin">
          <span class="btn">
            <Icon type="ios-briefcase-outline" :size="20"/>
            &nbsp;
            {{$t('label.administration')}}
          </span>
          <DropdownMenu slot="list">
            <DropdownItem name="users">
              <Icon type="ios-people-outline"></Icon>
              {{$t('label.usermanagement')}}
            </DropdownItem>
            <DropdownItem name="payments">
              <Icon type="ios-cash-outline"></Icon>
              {{$t('label.paymentmanagement')}}
            </DropdownItem>
            <DropdownItem name="associations">
              <Icon type="ios-clipboard-outline"></Icon>
              {{$t('label.associations')}}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div v-if="user">
        <Dropdown trigger="click" @on-click="onAccount">
          <span class="btn">
            <Icon type="ios-contact-outline" :size="22"></Icon>
            &nbsp;
            {{$store.state.auth.user.email}}
          </span>
          <DropdownMenu slot="list">
            <!--
            <DropdownItem name="settings" disabled>
              <Icon type="ios-settings-outline"></Icon>
              {{$t('label.settings')}}
            </DropdownItem>
            -->
            <DropdownItem name="logout">
              <Icon type="ios-log-out"></Icon>
              {{$t('action.logout')}}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div class="info">
        <div v-html="$t('copyright')"></div>
        <div>
          <RouterLink :to="{ name: 'imprint' }">{{$t('label.imprint')}}</RouterLink>
          <span>&#32;|&#32;</span>
          <RouterLink :to="{ name: 'privacy' }">{{$t('label.privacy')}}</RouterLink>
        </div>
        <div>v{{version}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        open_table: false
      }
    },
    computed: {
      user() {
        return this.$store.state.auth.user
      },
      version() {
        return process.env.PACKAGE_VERSION
      },
      table() {
        return this.$store.state.table
      },
      is_table() {
        return this.$route.name === 'table'
      },
      is_empty() {
        return !this.$store.state.table.rows
      },
      table_is_ready() {
        return this.is_table && this.has_table && this.$store.getters['table/is_ready']
      },
      has_table() {
        return !!this.$store.state.table._id
      },
      selection() {
        return this.$store.state.selection
      },
      selection_is_empty() {
        return this.$store.getters['selection/isEmpty']
      },
      can_report() {
        const header = this.$store.state.selection.header
        const infos = header && this.$store.getters['table/headerInfos'][header]
        return !!(infos && infos.config && this.$store.state.selection.rows.length && this.user && !this.user.admin)
      },
      correct_data: {
        get() {
          return this.$store.state.correct_data
        },
        set(v) {
          this.$store.commit('setCorrectData', v)
        }
      },
      show_hidden_columns: {
        get() {
          return this.$store.state.show_hidden_columns
        },
        set(v) {
          this.$store.commit('setShowHiddenColumns', v)
        }
      }
    },
    methods: {
      onTable(name) {
        if (name === 'delete') {
          this.$Modal.confirm({
            title: this.$t('label.attention'),
            content: this.$t('confirm.delete-table', { name: this.$store.state.table.name }),
            okText: this.$t('action.delete'),
            onOk: () => {
              this.$store.dispatch('table/delete')
            }
          })
        } else if (name === 'import') {
          this.$bus.$emit('open-import')
        } else if (name === 'export') {
          this.$bus.$emit('open-export')
        } else if (name === 'open') {
          this.$bus.$emit('open-table')
        } else if (name === 'statistics') {
          this.$bus.$emit('open-statistics')
        } else if (name === 'import-wizard') {
          this.$bus.$emit('open-import-wizard')
        }
      },
      onAccount(name) {
        if (name === 'logout') {
          this.$store.dispatch('auth/logout').then(() => this.$router.push({ name: 'login' }))
        }
      },
      onView(name) {
        if (name === 'show-hidden-columns') {
          this.$store.commit('setShowHiddenColumns', !this.$store.state.show_hidden_columns)
        }
      },
      onSelection(name) {
        if (name === 'cancel') {
          this.$store.commit('selection/reset')
        } else if (name === 'focus') {
          this.$store.commit('selection/setFocused', !this.$store.state.selection.focused)
        } else if (name === 'comment') {
          this.$bus.$emit('open-comment')
        } else if (name === 'report') {
          this.$bus.$emit('open-report')
        }
      },
      onAdmin(name) {
        if (name === 'users') {
          this.$bus.$emit('open-users')
        } else if (name === 'payments') {
          this.$bus.$emit('open-payments')
        } else if (name === 'associations') {
          this.$bus.$emit('open-associations')
        }
      },
      onBack() {
        this.$router.push({ name: 'table' })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './styles/theme';

  .menu-bar {
    height: $menu-bar-height;

    .inner {
      display: flex;
      height: 100%;
      padding: 0 0.5rem;
      border-bottom: 1px solid $col-border;
      align-items: center;
      user-select: none;

      > div {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .main {
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;

      > div {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .selection {
    cursor: pointer;
    //height: 100%;
    display: flex;
    align-items: center;

    &.selection {
      border-radius: 4px;
      padding: 4px;
      transition: color 280ms ease, background-color 280ms ease;
    }

    &.primary {
      color: #fff;
      background-color: $col-primary;
    }

    &.focused {
      color: #fff;
      background-color: $col-error;
    }
  }

  .info {
    font-size: 8px;
    line-height: 120%;
    text-align: right;
  }

  .toggle-hidden, .toggle-correcting {
    display: flex;
    align-items: center;

    > div {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
