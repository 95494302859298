<template>
  <div class="column-summary">
    <div class="heading">{{ $t("label.column-summary") }}:</div>
    <div class="inner">
      <Spin v-if="api_requests.num_pending > 0" fix></Spin>
      <div class="padded">
        <Select
          :value="header"
          :placeholder="$t('placeholder.select_column')"
          @on-change="onChange($event)"
        >
          <Option v-for="h in available_header" :key="h" :value="h">{{
            h
          }}</Option>
        </Select>
      </div>
      <template v-if="summary">
        <div class="padded">
          <p>
            <b>{{ $t("label.cells") }}:</b>
          </p>
          <table class="counts">
            <tr>
              <td>{{ $t("label.total") }}</td>
              <td>{{ summary.rows.total }}</td>
            </tr>
            <tr>
              <td>{{ $t("label.empty") }}</td>
              <td>{{ summary.rows.empty }}</td>
            </tr>
            <tr>
              <td>{{ $t("label.errors") }}</td>
              <td>{{ summary.rows.error }}</td>
            </tr>
            <tr>
              <td>{{ $t("label.corrected") }}</td>
              <td>{{ summary.rows.corrected }}</td>
            </tr>
            <tr>
              <td>
                <span class="clickable" @click="onSelectDupes">{{
                  $t("label.duplicates")
                }}</span>
                <tip
                  :text="$t('hint.how_duplicates_are_counted')"
                  placement="top-start"
                ></tip>
              </td>
              <td>{{ summary.rows.duplicates }}</td>
            </tr>
            <tr>
              <td>{{ $t("label.commented") }}</td>
              <td>{{ summary.rows.commented }}</td>
            </tr>
          </table>
        </div>
        <div class="padded">
          <b>{{ $t("label.cellvalues") }}:</b>
        </div>
        <div class="value-list-wrap">
          <List
            ref="value_list"
            :columns="value_columns"
            :data="summary.values"
            class="value-list padded"
          >
            <div slot="list-actions">
              <Button
                icon="ios-funnel-outline"
                :title="$t('action.select-all')"
                size="small"
                @click="onSelectAll"
              ></Button>
            </div>
            <span slot="v" slot-scope="{ item }">{{
              item.v || empty_value
            }}</span>
            <span slot="t" slot-scope="{ item }">{{ item.t }}</span>
            <span
              slot="c"
              slot-scope="{ item }"
              :class="{ 'count-success': !!item.c }"
              >{{ item.c }}</span
            >
            <span
              slot="e"
              slot-scope="{ item }"
              :class="{ 'count-error': !!item.e }"
              >{{ item.e }}</span
            >
            <span slot="actions" slot-scope="{ item }" class="actions">

              <span class="action-btn" @click="onSelect(item, 'new')">
                <Tooltip :content="$t('action.new_selection')" placement="top">
                  <Icon type="ios-funnel-outline"></Icon>
                </Tooltip>
              </span>
              <span class="action-btn" @click="onSelect(item, 'append')" >
                <Tooltip :content="$t('action.append_selection')" placement="top">
                  <Icon type="ios-funnel"></Icon>
                </Tooltip>
              </span>
              <!--
              <div class="action-btn">
                <Dropdown trigger="click" @on-click="onSelect(item, $event)">
                  <Icon type="ios-funnel-outline"></Icon>
                  <DropdownMenu slot="list">
                    <DropdownItem name="new">
                      {{ $t("action.new_selection") }}
                    </DropdownItem>
                    <DropdownItem name="append">
                      {{ $t("action.append_selection") }}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              -->
            </span>
          </List>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import APIMixin from "/mixins/api";
import List from "/components/list";

export default {
  components: {
    List
  },
  mixins: [APIMixin],
  props: ["scrollToHeader"],
  data() {
    return {
      summary: null,
      value_columns: [
        {
          key: "v",
          filter: true,
          sortable: true,
          label: this.$t("label.cellvalue")
        },
        {
          key: "t",
          sortable: true,
          width: 60,
          align: "right",
          label: this.$t("label.count")[0] + ".",
          label_tip: this.$t("label.count")
        },
        {
          key: "e",
          sortable: true,
          width: 60,
          align: "right",
          label: this.$t("label.errors")[0] + ".",
          label_tip: this.$t("label.errors")
        },
        {
          key: "c",
          sortable: true,
          width: 60,
          align: "right",
          label: this.$t("label.corrected")[0] + ".",
          label_tip: this.$t("label.corrected")
        },
        {
          width: 60,
          align: "center",
          slot: "actions"
        }
      ]
    };
  },
  computed: {
    header: {
      get() {
        return this.$store.state.ui.last_summary_header;
      },
      set(v) {
        this.$store.commit("ui/setLastSummaryHeader", v);
      }
    },
    available_header() {
      //const header = _.reject(this.$store.state.table.header, (h) => this.$store.getters['table/headerInfos'][h].hidden)
      const header = _.clone(this.$store.state.table.header);
      header.sort((a, b) => {
        return a.localeCompare(b, "de", { numeric: true });
      });
      return header;
    },
    empty_value() {
      return this.$t("label.empty-value-indicator");
    }
  },
  created() {
    this._unsubscribeStore = this.$store.subscribe((mutation, state) => {
      if (_.includes(["table/_load", "table/reset"], mutation.type)) {
        this.load(state.table._id ? this.header : null);
      }
    });
    this.$bus.$on("header-sorted", this.onHeaderSorted);
    this.$bus.$on("mapping-performed", this.onMappingPerformed);
    if (this.header) {
      this.load(this.header);
    }
  },
  beforeDestroy() {
    this.$bus.$off("mapping-performed", this.onMappingPerformed);
    if (this._unsubscribeStore) {
      this._unsubscribeStore();
    }
  },
  methods: {
    load(header) {
      if (this.$refs.value_list) {
        this.$refs.value_list.setFilterText("");
      }
      this.header = header || "";
      if (!this.header) {
        this.summary = null;
        return;
      }
      const data = { header: this.header };
      this.apiPost("summary", {
        url: `/table/${this.$store.state.table._id}/summary`,
        data
      }).then(
        summary => {
          this.summary = summary;
          this.summary.values.sort((a, b) => {
            return b.e - a.e;
          });
        },
        () => {
          this.summary = null;
        }
      );
    },
    onChange(header) {
      this.load(header);
      if (header && this.scrollToHeader) {
        this.scrollToHeader(header);
      }
    },
    onMappingPerformed() {
      this.load(this.header);
    },
    onHeaderSorted() {
      this.load(this.header);
    },
    onSelect(row, mode) {
      const header = this.summary.header;
      this.$store.commit("selection/setMultiple", {
        header,
        rows: row.i,
        append: mode === "append"
      });
      this.$store.commit("selection/setFocused", true);
    },
    onSelectAll() {
      let rows = [];
      for (const v of this.$refs.value_list.filtered_items) {
        rows = _.concat(rows, v.value.i);
      }
      rows = _.uniq(rows);
      if (!_.isEmpty(rows)) {
        this.$store.commit("selection/setMultiple", {
          header: this.summary.header,
          rows
        });
        this.$store.commit("selection/setFocused", true);
      }
    },
    onSelectDupes() {
      const rows = this.summary.duplicate_rows;
      if (!_.isEmpty(rows)) {
        this.$store.commit("selection/setMultiple", {
          header: this.summary.header,
          rows
        });
        this.$store.commit("selection/setFocused", true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/styles/theme";

.column-summary {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .inner {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.heading {
  font-weight: bold;
  background-color: $col-background;
  padding: 0.5rem;
  font-size: 1.16em;
  user-select: none;
}

.padded {
  padding: 0.5em;
}

.counts {
  width: 100%;
  border-collapse: collapse;

  td {
    border-bottom: 1px solid $col-border;

    &:last-child {
      text-align: right;
    }
  }
}

.value-list-wrap {
  flex: 1;
  position: relative;
}

.value-list {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .count-error,
  .count-success {
    font-weight: bold;
  }

  .count-error {
    color: $col-error;
  }

  .count-success {
    color: $col-success;
  }
}

.actions {
  display: flex;

  > span:first-child {
    margin-left: 6px;
    margin-right: 6px;
  }
}
</style>
