<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem>
      <FormItem :label="$t('label.output-format')">
        <Select v-model="transform.output" :disabled="readonly">
          <Option value="distance-km">{{$t('routing-output.distance-km')}}</Option>
        </Select>
      </FormItem>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'config', 'index', 'transform', 'options', 'readonly' ]
  }
</script>
