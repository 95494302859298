<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :title="$t('label.usermanagement')" @on-cancel="close">
    <div v-if="is_open" class="split">
      <Spin v-if="api_requests.num_pending > 0" fix></Spin>

      <List :data="users" :columns="user_columns" class="users-list">
        <span slot="actions" slot-scope="{ item }">
          <div class="action-btn" @click="selectUser(item)">
            <Icon type="ios-create-outline"></Icon>
          </div>
          <span class="action-btn danger" @click="deleteUser(item)">
            <Icon type="ios-trash-outline"></Icon>
          </span>
        </span>
      </List>
      <div class="editor">
        <Form v-if="selected" :label-width="160" @submit.native.prevent>
          <FormItem :label="$t('label.email')">
            <Input v-model.trim="selected.email" type="email" :disabled="readonly"></Input>
          </FormItem>
          <FormItem :label="$t('label.active')">
            <i-switch v-model="selected.active" size="large" :disabled="readonly">
              <span slot="open">{{$t('label.yes')}}</span>
              <span slot="close">{{$t('label.no')}}</span>
            </i-switch>
          </FormItem>
          <FormItem :label="$t('label.administrator')">
            <i-switch v-model="selected.admin" size="large" :disabled="readonly">
              <span slot="open">{{$t('label.yes')}}</span>
              <span slot="close">{{$t('label.no')}}</span>
            </i-switch>
          </FormItem>
          <FormItem v-if="!selected.admin" :label="$t('label.rights')">
            <Checkbox v-model="selected.rights.can_create_configurations">{{$t('user-rights.can_create_configurations')}}</Checkbox>
          </FormItem>
          <FormItem :label="$t('label.tags')">
            <TagList v-model="selected.tags" :readonly="readonly"></TagList>
            <Alert type="info" show-icon>{{$t('hint.public_config_and_user_tags')}}</Alert>
          </FormItem>
          <FormItem>
            <Button type="default" @click="empty">{{$t('action.reset')}}</Button>
            <Button type="primary" :disabled="readonly || !can_save" @click="save">{{$t(selected._id ? 'action.save' : 'action.create_user')}}</Button>
          </FormItem>
        </Form>
      </div>
    </div>
    <div slot="footer">
      <Button @click="close">{{$t('action.close')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import APIMixin from '/mixins/api'
  import List from '/components/list'
  import TagList from '/components/tag-list'

  const EMPTY_USER = {
    email: '',
    active: false,
    admin: false,
    tags: [],
    rights: {}
  }

  export default {
    components: {
      List,
      TagList
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        is_open: false,
        selected: _.cloneDeep(EMPTY_USER),
        users: [],
        user_columns: [
          {
            key: 'email',
            filter: true,
            sortable: true,
            label: this.$t('label.email')
          },
          {
            slot: 'actions',
            align: 'center',
            width: 50
          }
        ]
      }
    },
    computed: {
      readonly() {
        const user = this.$store.state.auth.user
        return user && user._id === this.selected._id
      },
      can_save() {
        return !!this.selected.email
      }
    },
    methods: {
      open() {
        this.is_open = true
        this.refresh()
        this.empty()
      },
      close() {
        this.is_open = false
        this.apiCancelAll()
      },
      async refresh() {
        this.users = await this.apiGet('users', '/users')
      },
      selectUser(user) {
        this.selected = _.cloneDeep(user)
      },
      deleteUser(user) {
        if (user._id === this.$store.state.auth.user._id) {
          return
        }
        this.$Modal.confirm({
          title: this.$t('label.attention'),
          content: this.$t('confirm.delete-user', { email: user.email }),
          okText: this.$t('action.delete'),
          onOk: () => {
            this.apiDel('user', `/user/${user._id}`)
              .then(() => {
                this.refresh()
              }, (err) => {
                this.$reportError(err)
                console.error(err)
              })
          }
        })
      },
      empty() {
        this.selected = _.cloneDeep(EMPTY_USER)
      },
      save() {
        this.apiPost('user', { url: '/user', data: this.selected })
          .then((user) => {
            this.refresh()
            this.selectUser(user)
          }, (err) => {
            this.$reportError(err)
            console.error(err)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .split {
    display: flex;
    position: relative;
  }

  .users-list {
    flex: 0.4;
    height: 300px;
    margin-right: 1rem;
  }

  .editor {
    flex: 1;
  }

  .tag-list {
    margin-bottom: 1em;
  }
</style>
