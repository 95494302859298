<template>
  <div :class="[ 'configuration-select', { small, invalid, empty, disabled } ]" @click="startSelect">
    <div v-if="hasPrefix" class="prefix" @click.prevent.stop="!disabled && !invalid && $emit('on-prefix')">
      <Icon v-if="invalid" type="ios-close-circle-outline"></Icon>
      <Icon v-else type="ios-create-outline"></Icon>
    </div>
    <div class="label">
      <span :title="label">{{label}}</span>
    </div>
    <!--
    <Cascader trigger="click" :disabled="disabled" :value="[]" :data="tree" @on-change="onChange">
      <span>{{label}}</span>
    </Cascader>
    -->
    <div v-if="allowEmpty && !disabled && !empty" class="clear" @click.prevent.stop="stopSelect('')">
      <Icon type="ios-close"></Icon>
    </div>
    <div v-if="is_open" v-on-clickaway="cancelSelect" :class="[ 'selector', { 'orient-left': orient_left, 'orient-right': !orient_left } ]" :style="`max-width:${max_width}px;`" @wheel.stop>
      <div class="inner">
        <div v-if="all_tags.length" class="tag-list">
          <span>{{$t('label.tags')}}:&#32;</span>
          <Tag v-for="t of all_tags" :key="t.name" :name="t.name" :checked="!isTagDisabled(t.name)" checkable :color="t.color || 'primary'" @on-change="onToggleTag">{{t.label}}</Tag>
        </div>
        <div class="results-wrap" :style="`max-height:${max_results_height}px;`">
          <div v-for="cfg of results" :key="cfg._id" class="entry" @click.prevent.stop="stopSelect(cfg._id)">
            <div class="name">{{cfg.name}}</div>
            <div v-if="cfg.description" class="description">{{cfg.description}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { directive as onClickaway } from 'vue-clickaway';

  const TAG_PUBLIC = '<public>'

  export default {
    directives: {
      onClickaway: onClickaway
    },
    props: {
      value: {
        type: String,
        required: true
      },
      allowEmpty: {
        type: Boolean,
        default: true
      },
      small: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hasPrefix: {
        type: Boolean,
        default: true
      },
      header: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        is_open: false,
        max_width: 100,
        max_results_height: 100,
        orient_left: false,
        disabled_tags: []
      }
    },
    computed: {
      invalid() {
        return this.missing
      },
      empty() {
        return !this.value
      },
      missing() {
        if (this.allowEmpty) {
          return this.value && !_.find(this.$store.state.configurations.all, { _id: this.value })
        } else {
          return !this.value || !_.find(this.$store.state.configurations.all, { _id: this.value })
        }
      },
      all_tags() {
        let tags = _.uniq(_.flatten(_.map(this.$store.state.configurations.all, 'tags')))
        tags = _.map(tags, (name) => {
          return { name, label: name }
        })
        tags =  _.concat(tags, [
          { name: TAG_PUBLIC, label: this.$t('tag-name.<public>') , color: 'success' }
        ])
        tags.sort((a, b) => {
          return a.name.localeCompare(b.name, 'de', { numeric: true })
        })
        return tags
      },
      results() {
        const user = this.$store.state.auth.user
        const tags = _.map(this.all_tags, 'name')
        _.pullAll(tags, this.disabled_tags)
        const has_public = _.includes(tags, TAG_PUBLIC)
        const res = _.reject(this.$store.state.configurations.all, (cfg) => {
          if (!_.isEmpty(cfg.tags) && _.isEmpty(_.intersection(cfg.tags, tags))) {
            return true
          }
          if (cfg.owner !== user._id && !has_public) {
            return true
          }
        })
        res.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        return res
      },
      label() {
        const cfg = _.find(this.$store.state.configurations.all, { _id: this.value })
        if (cfg) {
          return cfg.name
        } else {
          return this.value ? this.$t('label.deleted_configuration') : this.$t('placeholder.any')
        }
      }
    },
    watch: {
      value() {
        this.cancelSelect()
      }
    },
    mounted() {
      this.$bus.$on('raw-cell-click', this.onRawCellClick)
    },
    beforeDestroy() {
      this.$bus.$off('raw-cell-click', this.onRawCellClick)
    },
    methods: {
      startSelect() {
        if (this.disabled) {
          return
        }
        this.is_open = true
        const bounds = this.$el.getBoundingClientRect()
        this.max_width = Math.floor((window.innerWidth - bounds.left) * 0.8)
        this.max_results_height = Math.floor((window.innerHeight - bounds.bottom) * 0.8)
        this.orient_left = bounds.left < window.innerWidth * 0.5
        this.disabled_tags = []
      },
      stopSelect(id) {
        this.is_open = false
        if (!_.isUndefined(id)) {
          this.$emit('input', id, this.header)
        }
      },
      cancelSelect() {
        this.stopSelect()
      },
      isTagDisabled(tag) {
        return _.includes(this.disabled_tags, tag)
      },
      onToggleTag(checked, tag) {
        if (checked) {
          this.disabled_tags = _.without(this.disabled_tags, tag)
          return
        }
        if (!_.includes(this.disabled_tags, tag)) {
          this.disabled_tags.push(tag)
        }
      },
      onRawCellClick() {
        this.cancelSelect()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  $v-padd: 1.225em;
  $h-padd: 0.5em;

  $v-padd-small: 0.2em;
  $h-padd-small: 0.5em;

  .configuration-select {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid $col-border;
    border-radius: $input-border-radius;
    padding: $v-padd $h-padd;
    font-weight: normal;
    height: 1.8em;
    line-height: 1.8em;
    cursor: pointer;
    border-color: $col-success;

    transition: border-color 280ms ease;

    &.small {
      padding: $v-padd-small $h-padd-small;

      > div {
        margin-right: $h-padd-small;
      }
    }

    &.invalid {
      border-color: $col-error;

      .prefix, .label {
        color: $col-error;
      }
    }

    &.empty {
      color: $col-disabled;
      border-color: $col-primary;

      .prefix, .label {
        color: $col-primary;
      }
    }

    &.disabled {
      cursor: default;
      border-color: $col-border;

      .prefix, .label {
        color: $col-border;
      }
    }

    > div {
      margin-right: $h-padd;

      &:last-child {
        margin-right: 0;
      }
    }

    .ivu-icon {
      font-size: 1.3em;
    }
  }

  .prefix {
    color: $col-success;
  }

  .clear {
    cursor: pointer;
  }

  .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 100%;
    color: $col-success;
  }

  .selector {
    position: absolute;
    z-index: $z-cell-dropdown;
    top: 2px;
    min-width: 320px;
    box-shadow: 2px 2px 5px 1px rgba($col-dark-border, 0.8);
    border: 1px solid $col-dark-border;
    border-radius: $input-border-radius;
    background-color: #fff;
    color: $col-content;
    user-select: none;

    padding: 6px;

    &.orient-left {
      left: 2px;
    }

    &.orient-right {
      right: 2px;
    }

    .inner {
      position: relative;
    }
  }

  .tag-list {
    margin-bottom: 0.5rem;
  }

  .results-wrap {
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid $col-border;
    border-radius: $input-border-radius;
  }

  .entry {
    line-height: 140%;
    font-size: 12px;
    padding: 2px 3px;
    transition: background-color 280ms ease;

    &:hover {
      background-color: $col-divider;
    }

    .name {
      font-weight: bold;
    }

    .description {
      font-size: 10px;
      padding-left: 1em;
      font-style: italic;
    }
  }
</style>
