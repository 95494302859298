import _ from 'lodash'
import Vue from 'vue'
import api from '../services/api'

const MIN_HEADER_WIDTH = 120
const MAX_HEADER_WIDTH = 320
const DEFAULT_HEADER_WIDTH = 160

const state = {
  _last_id: '',
  _id: '',
  rows: 0,
  name: '',
  header: [],
  settings: {},
  importing: false,
  import_filename: '',
  paid: false
}

const mutations = {
  _load(state, table) {
    state._last_id = table._id,
    state._id = table._id
    state.rows = table.rows || 0
    state.name = table.name
    state.header = table.header || []
    if (table.__post_save) {
      for (const k in table.settings) {
        for (const p in table.settings[k]) {
          Vue.set(state.settings[k], p, table.settings[k][p])
        }
      }
      return
    } else {
      state.settings = table.settings || {}
    }
    state.importing = table.importing
    state.import_errors = table.import_errors
    state.import_filename = table.import_filename
    state.paid = table.paid
  },
  reset(state) {
    state._last_id = ''
    state._id = ''
    state.rows = 0
    state.name = ''
    state.header = []
    state.settings = {}
    state.importing = false
    state.import_errors = []
    state.import_filename = 0
    state.paid = false
  },
  setHeaderWidth(state, { header, width }) {
    if (!state.settings[header]) {
      Vue.set(state.settings, header, {})
    }
    width = Math.max(Math.min(width, MAX_HEADER_WIDTH), MIN_HEADER_WIDTH)
    Vue.set(state.settings[header], 'width', width)
  },
  setHeaderConfiguration(state, { header, config }) {
    if (!state.settings[header]) {
      Vue.set(state.settings, header, {})
    }
    Vue.set(state.settings[header], 'configuration', config)
    if (config) {
      Vue.set(state.settings[header], 'hidden', false)
      Vue.set(state.settings[header], 'references', {})
    }
  },
  setHeaderHidden(state, { header, hidden }) {
    if (!state.settings[header]) {
      Vue.set(state.settings, header, {})
    }
    Vue.set(state.settings[header], 'hidden', hidden)
    if (hidden) {
      Vue.set(state.settings[header], 'configuration', '')
      Vue.set(state.settings[header], 'references', {})
    }
  },
  setHeaderReference(state, { header, reference, value }) {
    if (!state.settings[header]) {
      Vue.set(state.settings, header, {})
    }
    if (!state.settings[header].references) {
      Vue.set(state.settings[header], 'references', {})
    }
    Vue.set(state.settings[header].references, reference, value)
  }
}

const getters = {
  is_ready: (state) => {
    if (PROFILING) {
      console.log('table:is_ready recompute')
    }
    return !!(state._id && !state.importing && _.isEmpty(state.import_errors))
  },
  headerInfos: (state, getters, rootState/*, rootGetters*/) => {
    if (PROFILING) {
      console.log('table:headerInfos recompute')
    }
    const res = {}
    for (const h of state.header) {
      const settings = state.settings[h]
      const config = settings && settings.configuration
      res[h] = {
        width: (settings && settings.width) || DEFAULT_HEADER_WIDTH,
        hidden: (settings && settings.hidden) || false,
        config: config ? _.find(rootState.configurations.all, { _id: config }) : null
        //config: config ? rootGetters['configurations/map'][config] : null
      }
    }
    return res
  }
}

const actions = {
  async load({ commit }, id) {
    commit('selection/reset', null, { root: true })
    commit('reset')
    const table = await api.get(`/table/${id}`)
    commit('_load', table)
  },
  async reload({ commit, state }) {
    const table = await api.get(`/table/${state._id}`)
    commit('_load', table)
  },
  async save({ commit, state }) {
    const table = await api.post('/table', state)
    table.__post_save = true
    commit('_load', table)
    return table
  },
  async delete({ state, commit }) {
    const id = state._id
    commit('selection/reset', null, { root: true })
    commit('reset')
    const res = await api.delete(`/table/${id}`)
    return res
  },
  async import({ state, commit, dispatch }, { file, setup }) {
    commit('selection/reset', null, { root: true })
    const data = new FormData()
    data.append('file', file)
    data.append('setup', JSON.stringify(setup))
    const res = await api.put(`/table/${state._id}/import`, data)
    dispatch('load', state._id)
    return res
  }
}

export default { namespaced: true, state, mutations, actions, getters }
