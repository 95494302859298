<template>
  <Form :label-width="100" @submit.native.prevent>
    <FormItem :label="$t('action.allow')">
      <i-switch v-model="transform.allowed" :disabled="readonly"></i-switch>
    </FormItem>
    <FormItem v-show="!transform.allowed" :label="$t('action.replace_with')">
      <Input :value="transform.default" :placeholder="$t('label.optional')" :disabled="readonly" clearable @on-enter="transform.default = $event.target.value"></Input>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly' ]
  }
</script>
