<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :title="$t('label.paymentmanagement')" @on-cancel="close">
    <div v-if="is_open" class="split">
      <Spin v-if="api_requests.num_pending > 0" fix></Spin>
      <List :data="payments" :columns="columns" class="users-list">
        <span slot="updatedAt" slot-scope="{ item }">
          {{formatDatetime(item.updatedAt)}}
        </span>
        <span slot="status" slot-scope="{ item }">
          <span v-if="item.paid">{{$t('label.paid')}}</span>
          <span v-else>&ndash;</span>
        </span>
        <span slot="actions" slot-scope="{ item }">
          <span v-if="item.paid" class="action-btn" @click="markAsPaid(item._id, false)">{{$t('action.mark_unpaid')}}</span>
          <span v-else class="action-btn" @click="markAsPaid(item._id, true)">{{$t('action.mark_paid')}}</span>
        </span>
      </List>
    </div>
    <div slot="footer">
      <Button @click="close">{{$t('action.close')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import moment from 'moment'
  import APIMixin from '/mixins/api'
  import List from '/components/list'

  export default {
    components: {
      List
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        is_open: false,
        payments: [],
        columns: [
          {
            key: 'name',
            filter: true,
            sortable: true,
            label: this.$t('label.table')
          },
          {
            key: '_owner_email',
            filter: true,
            sortable: true,
            label: this.$t('label.username')
          },
          {
            key: 'updatedAt',
            minWidth: 180,
            sortable: true,
            label: this.$t('label.updated-at')
          },
          {
            slot: 'status',
            sortable: true,
            width: 120,
            label: this.$t('label.status')
          },
          {
            slot: 'actions',
            align: 'right'
          }
        ]
      }
    },
    methods: {
      formatDatetime(d) {
        return moment(d).format('DD.MM.YYYY HH:mm:ss')
      },
      open() {
        this.is_open = true
        this.refresh()
      },
      close() {
        this.is_open = false
        this.apiCancelAll()
      },
      async refresh() {
        this.payments = await this.apiGet('payments', '/payments')
      },
      markAsPaid(id, paid) {
        const data = { paid }
        this.apiPost({ url: `/payments/mark-paid/${id}`, data })
          .then(this.refresh, this.refresh)
      }
    }
  }
</script>
