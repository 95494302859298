<template>
  <Alert type="error" show-icon>
    <span v-html="text"></span>
  </Alert>
</template>

<script>
  export default {
    props: {
      error: {
        type: [ Object, Error ],
        required: true
      }
    },
    computed: {
      text() {
        let txt = this.error.toString()
        const key = this.error.id && `error.${this.error.id}`
        if (this.$te(key)) {
          const data = this.error.data || this.error.extra || {}
          /*
          if (_.isFinite(data.row)) {
            data.row += 2
          }
          if (data.expected) {
            data.expected = this.$t(`datatype.${data.expected}`)
          }
          */
          txt = this.$t(key, data)
        }
        return txt
      }
    }
  }
</script>
