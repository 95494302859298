import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const PRODUCTION = process.env.NODE_ENV === 'production'

Vue.use(Vuex)

import auth from './auth'
import ui from './ui'
import table from './table'
import selection from './selection'
import configurations from './configurations'

const store = new Vuex.Store({
  strict: !PRODUCTION,
  state: {
    loading: false,
    show_hidden_columns: false,
    correct_data: true
  },
  mutations: {
    setLoading(state, on) {
      state.loading = !!on
    },
    setShowHiddenColumns(state, on) {
      state.show_hidden_columns = !!on
    },
    setCorrectData(state, on) {
      state.correct_data = !!on
    }
  },
  modules: {
    auth,
    ui,
    table,
    selection,
    configurations
  },
  plugins: [
    createPersistedState({
      paths: [
        'show_hidden_columns',
        'correct_data',
        'table._last_id'
      ]
    })
  ]
})

export default store
