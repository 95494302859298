<template>
  <div>
    <!--<div class="section-title">{{ $t('label.entries') }} ({{ transform.entries.length }})</div>-->
    <div class="section-wrap">
      <List :data="transform.entries" :columns="entries_columns" class="entries-list">
        <span slot="replace" slot-scope="{ item }">
          {{item.searchtext}}
          <Icon type="ios-arrow-forward"></Icon>
          {{item.replacementtext || empty_value}}
        </span>
        <span slot="searchmode" slot-scope="{ item }">
          {{$t(`replace-searchmode.${item.searchmode}`)}}
        </span>
        <span slot="ignore_case" slot-scope="{ item }">
          {{$t(item.ignore_case ? 'label.yes' : 'label.no')}}
        </span>
        <span slot="actions" slot-scope="{ itemIndex }" class="actions">
          <span class="action-btn" @click="editEntry(itemIndex)">
            <Icon type="ios-create-outline"></Icon>
          </span>
          <span class="action-btn danger" @click="removeEntry(itemIndex)">
            <Icon type="ios-trash-outline"></Icon>
          </span>
          <span class="action-btn" @click="moveEntry(itemIndex, -1)">
            <Icon type="ios-arrow-dropup"></Icon>
          </span>
          <span class="action-btn" @click="moveEntry(itemIndex, 1)">
            <Icon type="ios-arrow-dropdown"></Icon>
          </span>
        </span>
      </List>
    </div>
    <template v-if="!readonly">
      <div class="section-title">{{ $t('action.add_entry') }}</div>
      <Form :label-width="150" @submit.native.prevent>
        <FormItem :label="$t('label.ignore_case')">
          <i-switch v-model="add_entry.ignore_case"></i-switch>
        </FormItem>
        <FormItem :label="$t('label.replace_searchmode')">
          <Select v-model="add_entry.searchmode">
            <Option v-for="m in valid_searchmodes" :key="m" :value="m">{{$t(`replace-searchmode.${m}`)}}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('label.replace_searchtext')">
          <Input v-model="add_entry.searchtext"></Input>
        </FormItem>
        <FormItem :label="$t('label.replace_replacementtext')">
          <Input v-model="add_entry.replacementtext"></el-input>
        </FormItem>
        <FormItem>
          <Button type="primary" icon="ios-add-circle-outline" :disabled="!can_add" @click="addEntry">
            {{ edit_index >= 0 ? $t('action.save') : $t('action.add') }}
          </Button>
        </FormItem>
      </Form>
      <div style="height:1rem;"></div>
      <TransformImportExport :config="config" :transform-index="index" prefix="Ersetzen" @imported="$emit('refresh')"></TransformImportExport>
    </template>
  </div>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import TransformImportExport from './transform-import-export'

  export default {
    components: {
      List,
      TransformImportExport
    },
    props: [ 'config', 'index', 'transform', 'options', 'readonly' ],
    data() {
      return {
        edit_index: -1,
        add_entry: _.cloneDeep(this.options.empty_entry)
      }
    },
    computed: {
      empty_value() {
        return this.$t('label.empty-value-indicator')
      },
      valid_searchmodes() {
        return this.options.searchmodes
      },
      can_add() {
        return this.add_entry.searchmode && this.add_entry.searchtext
      },
      entries_columns() {
        return _.compact([
          {
            slot: 'replace',
            label: this.$t('action.replace')
          },
          {
            key: 'searchmode',
            width: 100,
            align: 'right',
            label: this.$t('label.replace_searchmode')
          },
          {
            key: 'ignore_case',
            width: 70,
            align: 'right',
            label: this.$t('label.case_nocase_short')
          },
          !this.readonly && {
            slot: 'actions',
            width: 80,
            align: 'center'
          }
        ])
      }
    },
    methods: {
      resetEntry() {
        this.add_entry = _.cloneDeep(this.options.empty_entry)
        this.edit_index = -1
      },
      addEntry() {
        if (!this.can_add) {
          return
        }
        if (this.edit_index >= 0) {
          this.transform.entries.splice(this.edit_index, 1, this.add_entry)
        } else {
          this.transform.entries.push(this.add_entry)
        }
        this.resetEntry()
      },
      removeEntry(idx) {
        this.transform.entries.splice(idx, 1)
        this.resetEntry()
      },
      editEntry(idx) {
        this.edit_index = idx
        this.add_entry = _.cloneDeep(this.transform.entries[idx])
      },
      moveEntry(idx, dir) {
        const nidx = idx + dir
        if (nidx < 0 || nidx > _.size(this.transform.entries) - 1) {
          return
        }
        const prev = this.transform.entries[nidx]
        this.$set(this.transform.entries, nidx, this.transform.entries[idx])
        this.$set(this.transform.entries, idx, prev)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .section-title {
    line-height: 200%;
    font-weight: 600;
    margin-bottom: 1rem;
    border-bottom: 1px solid $col-border;
  }

  .section-wrap {
    margin-bottom: 1rem;
  }

  .limit-text {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .entries-list {
    height: 300px;
  }

  .delete-btn {
    cursor: pointer;
    color: $col-error;
  }

  .actions {
    display: flex;
  }
</style>
