<template>
  <div ref="viewport" :key="$store.state.table._id" class="view">
    <div
      v-show="is_saving || is_sorting"
      class="blocked"
      @click.prevent.stop
    ></div>


    <div v-if="corrector_open" class="corrector-wrap">
      <ColumnCorrector
        ref="column_corrector"
        :scroll-to-header="scrollToHeader"
      ></ColumnCorrector>
    </div>
    <div
      :class="['corrector-toggle', { open: corrector_open }]"
      @click="toggleCorrector"
    >
      <Icon type="ios-medkit-outline" :size="22"></Icon>
      <div class="text">{{ $t("label.column-corrector") }}</div>
    </div>

    <div v-if="summary_open" class="summary-wrap">
      <ColumnSummary
        ref="column_summary"
        :scroll-to-header="scrollToHeader"
      ></ColumnSummary>
    </div>
    <div
      :class="['summary-toggle', { open: summary_open }]"
      @click="toggleSummary"
    >
      <Icon type="ios-color-filter-outline" :size="22"></Icon>
      <div class="text">{{ $t("label.column-summary") }}</div>
    </div>

    <template v-if="can_create_configurations">
      <div v-if="configs_open" class="editor-wrap">
        <ConfigurationEditor ref="config_editor"></ConfigurationEditor>
      </div>
      <div
        :class="['config-toggle', { open: configs_open }]"
        @click="toggleConfigs"
      >
        <Icon type="ios-create-outline" :size="22"></Icon>
        <div class="text">{{ $t("label.column_configurations") }}</div>
      </div>
    </template>

    <div v-if="!ready" class="not-ready-wrap">
      <NotReady></NotReady>
    </div>

    <div v-if="ready" class="row-column">
      <table>
        <tr>
          <th :style="`height:${header_height}px;`">
            <template v-if="$store.state.table.rows">
              <div
                v-if="num_error_header"
                class="btn-header-error"
                @click="gotoErrorHeader"
              >
                <Icon type="ios-alert"></Icon>&#32;{{ num_error_header }}
              </div>
              <template v-else>
                <div class="btn-header-reset" @click="gotoStart">
                  <Icon type="ios-compass-outline" :size="22"></Icon>
                </div>
                <div class="btn-header-prevcolumn" @click="gotoCol(-1)">
                  <Icon type="ios-arrow-dropleft" :size="22"></Icon>
                </div>
                <div class="btn-header-nextcolumn" @click="gotoCol(1)">
                  <Icon type="ios-arrow-dropright" :size="22"></Icon>
                </div>
              </template>
            </template>
          </th>
        </tr>
        <tr v-for="(row, rowi) in row_infos" :key="`rows-row-${rowi}`">
          <td :class="{ errors: row.errors, selection: row.selection }">
            {{ row.index + 1 }}
          </td>
        </tr>
      </table>
    </div>
    <div v-if="ready" ref="data" class="data" @wheel="onScrollWheel">
      <table>
        <tr ref="header_row">
          <template v-for="h in $store.state.table.header">
            <Header
              v-show="
                !header_infos[h].hidden || $store.state.show_hidden_columns
              "
              ref="headers"
              :key="`header-${h}`"
              :header="h"
              :complete="!header_infos[h].has_errors"
              :errors="filtered_errors[h]"
              :max-height="header_height"
              :style="`width:${header_infos[h].width}px;`"
              @sort="onHeaderSort"
              >{{ h }}</Header
            >
          </template>
          <th :style="`width:${total_header_width}px;`">&nbsp;</th>
        </tr>
        <tr v-for="(row, rowi) in rows" :key="`data-row-${rowi}`">
          <td
            v-for="h in $store.state.table.header"
            v-show="!header_infos[h].hidden || $store.state.show_hidden_columns"
            :key="`data-${rowi}-${h}`"
          >
            <Cell
              :config="header_infos[h].config"
              :result="row.results[h]"
              :value="row.columns[h]"
              :comment="row.comments[h]"
              :header="h"
              :row="row.index"
              :get-suggestions="getSuggestions"
              :disabled="!!num_error_header || header_infos[h].hidden"
              @change="onCellChange($event, row.columns[h], row, h)"
            ></Cell>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>

    <Scrollbar
      v-if="ready"
      :first-row="offset"
      @scroll="onScrollBar"
    ></Scrollbar>
  </div>
</template>

<script>
import _ from "lodash";
import APIMixin from "/mixins/api";
import WindowResizeMixin from "../../mixins/window-resize";
import MethodIntervalMixin from "../../mixins/method-interval";
import Header from "./header";
import Cell from "./cell";
import Scrollbar from "./scrollbar";
import ConfigurationEditor from "/components/configuration-editor";
import ColumnSummary from "/components/column-summary";
import ColumnCorrector from "/components/column-corrector";
import NotReady from "./not-ready";

const ROW_HEIGHT = 20 + 2;
const ERROR_HEADER_WIDTHS = 300;

export default {
  components: {
    Header,
    Scrollbar,
    Cell,
    ColumnSummary,
    ColumnCorrector,
    ConfigurationEditor,
    NotReady
  },
  mixins: [APIMixin, MethodIntervalMixin, WindowResizeMixin],
  data() {
    return {
      offset: 0,
      num_visible: 0,
      header_height: 0,
      rows: [],
      errors: {},
      configs_open: false,
      summary_open: false,
      corrector_open: false,
      is_sorting: false,
      is_refreshing: false,
      is_saving: false
    };
  },
  computed: {
    ready() {
      return this.$store.getters["table/is_ready"];
    },
    can_create_configurations() {
      const user = this.$store.state.auth.user;
      return (
        user &&
        (user.admin || (user.rights && user.rights.can_create_configurations))
      );
    },
    total_header_width() {
      const show_hidden_columns = this.$store.state.show_hidden_columns;
      return _.sumBy(this.$store.state.table.header, h => {
        const info = this.header_infos[h];
        return (
          (show_hidden_columns ? info.width : !info.hidden && info.width) || 0
        );
      });
    },
    num_total_rows() {
      if (this.$store.state.selection.focused) {
        return _.size(this.$store.state.selection.rows);
      } else {
        return this.$store.state.table.rows;
      }
    },
    row_infos() {
      const selected_rows = this.$store.state.selection.rows;
      return _.map(this.rows, row => {
        let errors = false;
        for (const r in row.results) {
          errors = errors || !!row.results[r].error;
        }
        return {
          index: row.index,
          errors,
          selection: selected_rows.indexOf(row.index) >= 0
        };
      });
    },
    header_infos() {
      const info = {};
      const hinf = this.$store.getters["table/headerInfos"];
      const table = this.$store.state.table;
      for (const h of table.header) {
        info[h] = {
          width: this.errors[h] ? ERROR_HEADER_WIDTHS : hinf[h].width,
          hidden: !!(table.settings[h] && table.settings[h].hidden),
          config: hinf[h].config,
          has_errors: !_.isEmpty(this.errors[h])
        };
      }
      return info;
    },
    num_error_header() {
      let num = 0;
      for (const h in this.errors) {
        num += _.isEmpty(this.errors[h]) ? 0 : 1;
      }
      return num;
    },
    filtered_errors() {
      const res = {};
      for (const h in this.errors) {
        const e = _.filter(this.errors[h], l => {
          return !_.isEmpty(
            _.without(
              l.errors,
              "header-reference-missing",
              "configuration-not-found"
            )
          );
        });
        if (!_.isEmpty(e)) {
          res[h] = e;
        }
      }
      return res;
    }
  },
  watch: {
    num_visible(v) {
      this.throttledRefresh(`num_visible watcher (${v})`);
    },
    offset(v) {
      this.throttledRefresh(`offset watcher (${v})`);
    },
    "$store.state.table._id"() {
      this.toggleConfigs(false);
      this.toggleSummary(false);
    },
    "$store.state.selection.focused"(focused) {
      if (focused) {
        this.offset = 0;
      }
    }
  },
  created() {
    this._refresh_counter = 0;
    this.throttledRefresh = _.throttle((src, save) => {
      if (!src) {
        throw new Error("missing refresh source");
      }
      if (PROFILING) {
        console.log(
          `[${this._refresh_counter}] refresh requested from "${src}"`
        );
      }
      this._needs_refresh = true;
      this._needs_save = save || false;

      this.$nextTick(() => {
        this._refresh();
      });
    }, 250);

    this._unsubscribeStore = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "table/setHeaderHidden") {
        const sh = state.selection.header;
        if (sh && state.table.settings[sh] && state.table.settings[sh].hidden) {
          this.$store.commit("selection/reset");
        }
      }

      const save_and_refresh = [
        "table/_load",
        "table/setHeaderConfiguration",
        "table/setHeaderHidden",
        "table/setHeaderReference"
      ];
      const refresh = [
        "configurations/_load",
        "configurations/reset",
        "table/reset",
        "setCorrectData",
        "selection/reset",
        "selection/addRows",
        "selection/setFocused"
      ];
      if (mutation.type === "table/_load") {
        this.offset = 0;
      }
      const request = save => {
        if (PROFILING) {
          console.log("requesting due to mutation", mutation.type);
        }
        this.throttledRefresh("store subscriber", save);
      };
      if (_.includes(save_and_refresh, mutation.type)) {
        let update = true;
        if (
          mutation.type === "table/_load" &&
          this.$store.state.table._id === state.table._id
        ) {
          update = false;
        }
        if (update) {
          request(true);
        }
      } else if (_.includes(refresh, mutation.type)) {
        request();
      }
    });

    this.$bus.$on("goto-config", this.onGotoConfig);
    this.$bus.$on("goto-summary", this.onGotoSummary);
    this.$bus.$on("goto-corrector", this.onGotoCorrector);
    this.$bus.$on("table-request-refresh", this.onBusRequestRefresh);
    this.$bus.$on("mapping-performed", this.onMappingPerformed);
  },
  mounted() {
    this.methodInterval("refresh", this.onResizeInterval, 250);
    this.onWindowResize();
  },
  beforeDestroy() {
    this.cancelMethodInterval("refresh");
    if (this._unsubscribeStore) {
      this._unsubscribeStore();
    }
    this.$bus.$off("goto-config", this.onGotoConfig);
    this.$bus.$off("goto-summary", this.onGotoSummary);
    this.$bus.$off("goto-corrector", this.onGotoCorrector);
    this.$bus.$off("table-request-refresh", this.onBusRequestRefresh);
    this.$bus.$off("mapping-performed", this.onMappingPerformed);
  },
  methods: {
    onWindowResize() {
      this.header_height =
        (this.$refs.header_row && this.$refs.header_row.clientHeight) || 0;
      this.num_visible = Math.floor(
        (this.$refs.viewport.clientHeight - this.header_height) / ROW_HEIGHT
      );
    },
    onResizeInterval() {
      const hh =
        (this.$refs.header_row && this.$refs.header_row.clientHeight) || 0;
      if (this.header_height !== hh) {
        this.onWindowResize();
      }
    },
    onBusRequestRefresh() {
      this.throttledRefresh("from $bus::table-request-refresh");
    },
    onMappingPerformed() {
      this.throttledRefresh("onCellChange");
    },
    _refresh() {
      if (this.is_refreshing || this.is_sorting || this.is_saving) {
        return;
      }
      const clear = () => {
        this.is_refreshing = false;
        this._needs_refresh = false;
        this._needs_save = false;
      };
      if (!this.num_visible || !this.$store.state.table._id) {
        this.rows = [];
        this.errors = {};
        clear();
        return;
      }
      const perform = () => {
        const table = this.$store.state.table;
        const start = _.now();
        const data = {
          correct: this.$store.state.correct_data
        };
        if (this.$store.state.selection.focused) {
          data.indices = this.$store.state.selection.rows.slice(
            this.offset,
            this.offset + this.num_visible
          );
        } else {
          data.offset = this.offset;
          data.size = Math.max(
            0,
            Math.min(this.num_visible, this.num_total_rows - this.offset - 1)
          );
        }
        this.is_refreshing = true;
        this.apiPost("view", { url: `/table/${table._id}/view`, data }).then(
          view => {
            if (PROFILING) {
              const dur = _.now() - start;
              const kb = Math.ceil(_.size(JSON.stringify(view)) / 1024);
              console.log(
                `[${this._refresh_counter++}] view ${JSON.stringify(
                  data
                )} refreshed in ${dur}ms (${kb}kb). (${this.offset})`
              );
            }
            this.errors = view.errors || {};
            this.rows = view.rows || [];
            clear();
          },
          err => {
            this.rows = [];
            this.errors = {};
            clear();
            this.$reportError(err);
            console.error(err);
          }
        );
      };
      if (!this._needs_save) {
        perform();
      } else {
        this.is_saving = true;
        const start = _.now();
        if (PROFILING) {
          console.log("saving...");
        }
        this.$store.dispatch("table/save").then(
          () => {
            if (PROFILING) {
              console.log(`done saving in ${_.now() - start}ms`);
            }
            this.is_saving = false;
            this._needs_save = false;
            perform();
          },
          () => {
            this.is_saving = false;
            this._needs_save = false;
            perform();
          }
        );
      }
    },
    onHeaderSort(header, mode) {
      if (this.is_sorting) {
        return;
      }
      const start = _.now();
      const data = { header, mode };
      this.is_sorting = true;
      this.apiPost("sort", {
        url: `/table/${this.$store.state.table._id}/sort`,
        data
      }).then(
        () => {
          if (PROFILING) {
            console.log(`sorting finished in ${_.now() - start}ms`);
          }
          this.is_sorting = false;
          this.throttledRefresh("onHeaderSort done");
          this.$store.commit('selection/reset');
          this.$bus.$emit("header-sorted", data);
        },
        err => {
          this.is_sorting = false;
          this.$reportError(err);
          console.error(err);
        }
      );
    },
    scrollView(dy) {
      this.offset += Math.round(dy);
      this.offset = Math.min(
        this.offset,
        this.num_total_rows - 1 /*this.num_visible + 1*/
      );
      this.offset = Math.max(this.offset, 0);
    },
    onScrollBar(sy) {
      this.offset = sy;
    },
    onScrollWheel(e) {
      if (e.deltaX === 0 && !e.shiftKey) {
        let dy = e.deltaY || 0;
        dy = Math.sign(dy) * 1;
        dy = dy * (e.ctrlKey ? this.num_visible : 1);
        this.scrollView(dy);
        e.preventDefault();
        e.stopPropagation();
      } else if (e.deltaX) {
        // prevent browser gesture navigation
        const t = this.$refs.data;
        var maxX = t.scrollWidth - t.offsetWidth;
        if (t.scrollLeft + e.deltaX < 0 || t.scrollLeft + e.deltaX > maxX) {
          e.preventDefault();
          t.scrollLeft = Math.max(0, Math.min(maxX, t.scrollLeft + e.deltaX));
        }
      }
    },
    scrollToHeader(h) {
      const idx = this.$store.state.table.header.indexOf(h);
      const hh = idx >= 0 && this.$refs.headers && this.$refs.headers[idx];
      const sx = hh ? hh.$el.offsetLeft : 0;
      this.$refs.data.scrollLeft = sx;
    },
    onGotoConfig(opt) {
      if (opt.header) {
        this.scrollToHeader(opt.header);
      }
      this.toggleConfigs(true);
      this.$nextTick(() => {
        if (opt.id) {
          this.$refs.config_editor.load(opt.id);
        } else {
          this.$refs.config_editor.empty();
        }
      });
    },
    onGotoSummary(opt) {
      if (opt.header) {
        this.scrollToHeader(opt.header);
      }
      this.toggleSummary(true);
      this.$nextTick(() => {
        this.$refs.column_summary.load(opt.header);
      });
    },
    onGotoCorrector(opt) {
      if (opt.header) {
        this.scrollToHeader(opt.header);
      }
      this.toggleCorrector(true);
      this.$nextTick(() => {
        this.$refs.column_corrector.reinit(opt.header);
      });
    },
    gotoStart() {
      this.offset = 0;
      this.$refs.data.scrollLeft = 0;
    },
    gotoCol(dir) {
      const headers = this.$store.state.table.header
      for (let i = 0; i < headers.length; ++i) {
        const h = headers[i]
        const inf = this.$store.getters["table/headerInfos"][h];
        if (!inf || (inf.hidden && !this.$store.state.show_hidden_columns)) {
          continue
        }
        const hx = this.$refs.headers[i].$el.offsetLeft;
        if (hx >= this.$refs.data.scrollLeft) {
          let ni = i
          while (ni >= 0 && ni <= headers.length - 1) {
            ni += dir
            const nh = headers[ni]
            if (!nh) {
              continue
            }
            const ninf = this.$store.getters["table/headerInfos"][nh];
            if (!ninf || (ninf.hidden && !this.$store.state.show_hidden_columns)) {
              continue
            }
            const nhx = this.$refs.headers[ni].$el.offsetLeft;
            this.$refs.data.scrollLeft = nhx
            break
          }
          break
        }
      }
    },
    gotoErrorHeader() {
      for (const h in this.errors) {
        if (!_.isEmpty(this.errors[h])) {
          this.scrollToHeader(h);
          break;
        }
      }
    },
    async getSuggestions(config_id, query) {
      const suggestions = await this.apiPost("suggestion", {
        url: `/configuration/${config_id}/suggestions`,
        data: { query }
      });
      return suggestions;
    },
    async onCellChange(value, ovalue, row, header) {
      const selection = this.$store.state.selection;
      const cfg = this.$store.getters["table/headerInfos"][header].config;
      if (!ovalue || !cfg || !cfg._can_map || header !== selection.header) {
        return;
      }
      const data = {
        header: selection.header,
        indices: selection.rows,
        value: value,
        dryrun: true
      };
      const report = await this.apiPost("map", {
        url: `/table/${this.$store.state.table._id}/mapping`,
        data
      });
      this.$bus.$emit("mapping-request", report, _.omit(data, "dryrun"));
    },
    toggleConfigs(v) {
      const summary_was_open = this.summary_open;
      const open = _.isBoolean(v) ? v : !this.configs_open;
      this.configs_open = open;
      this.summary_open = open ? false : this.summary_open;
      this.corrector_open = open ? false : this.corrector_open;
      if (summary_was_open && open) {
        const header = this.$refs.column_summary.header;
        const config = header && this.header_infos[header].config;
        if (header) {
          this.scrollToHeader(header);
        }
        if (config) {
          this.$nextTick(() => {
            this.$refs.config_editor.load(config._id);
          });
        }
      }
    },
    toggleSummary(v) {
      this.summary_open = _.isBoolean(v) ? v : !this.summary_open;
      this.configs_open = this.summary_open ? false : this.configs_open;
      this.corrector_open = this.summary_open ? false : this.corrector_open;
    },
    toggleCorrector(v) {
      this.corrector_open = _.isBoolean(v) ? v : !this.corrector_open;
      this.summary_open = this.corrector_open ? false : this.summary_open;
      this.configs_open = this.corrector_open ? false : this.configs_open;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/theme";

.view {
  position: relative;
  display: flex;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

table {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  user-select: none;
}

th,
td {
  border: 1px solid $col-border;
  padding: 0;
}

th {
  border-top: 0;
}

.data {
  position: relative;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    width: 100%;
  }

  th {
    border-right: 0;
    border-left: 0;
  }

  td {
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
}

.row-column {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  th,
  td {
    background-color: $col-background;
  }

  th {
    width: 80px;
  }

  td {
    text-align: center;
    font-weight: bold;
    padding: 1px;

    &.errors {
      color: $col-error;
    }

    &.selection {
      background-color: $col-primary-very-light;
    }
  }
}

.blocked {
  pointer-events: none;
  position: absolute;
  z-index: $z-table-blocker;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(#fff, 0.8);
}

.not-ready-wrap {
  flex: 1;
}

.editor-wrap,
.corrector-wrap,
.summary-wrap {
  width: 640px;
  height: 100%;
  border: 1px solid $col-border;
  border-top: 0;
  border-bottom: 0;
}

.editor-wrap {
  overflow-x: hidden;
  overflow-y: auto;
}

.summary-wrap, .corrector-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.btn-header-reset,
.btn-header-nextcolumn,
.btn-header-prevcolumn {
  cursor: pointer;
  transition: color 280ms ease;

  &:hover {
    color: $col-primary;
  }
}

.btn-header-error {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  color: $col-error;

  .ivu-icon {
    line-height: 100%;
    font-size: 24px;
  }
}

.config-toggle,
.corrector-toggle,
.summary-toggle {
  cursor: pointer;
  width: 26px;
  background-color: $col-divider;
  border-right: 1px solid $col-border;
  user-select: none;
  text-align: center;
  transition: color 280ms ease;

  &:hover {
    color: $col-primary;
  }

  &.open {
    .text {
      font-weight: bold;
    }
  }

  .text {
    text-align: center;
    transform: translateY(10px) rotate(90deg);
  }
}
</style>
