<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem>
      <List :data="transform.table" :columns="table_columns" class="table-list">
        <span slot="from" slot-scope="{ itemIndex }">
          {{itemIndex || empty_value}}
        </span>
        <span slot="to" slot-scope="{ item }">
          {{item || empty_value}}
        </span>
        <span slot="actions" slot-scope="{ itemIndex }">
          <span class="action-btn danger" @click="deleteItem(itemIndex)">
            <Icon type="ios-trash-outline"></Icon>
          </span>
        </span>
      </List>
    </FormItem>
    <FormItem v-if="!readonly">
      <div class="add-inputs">
        <Input v-model.trim="add_from" :placeholder="$t('label.cellvalue')"></Input>
        <div class="spacer"><Icon type="ios-arrow-forward"></Icon></div>
        <Input v-model.trim="add_to" :placeholder="$t('label.comparevalue')" @on-enter="addMatch"></Input>
        <div class="spacer"></div>
        <Button type="primary" :disabled="!can_add" icon="ios-add-circle-outline" @click="addMatch">{{ $t('action.add') }}</Button>
      </div>
    </FormItem>

    <TransformImportExport v-if="!readonly" :config="config" :transform-index="index" prefix="Übereinstimmung" @imported="$emit('refresh')"></TransformImportExport>
  </Form>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import TransformImportExport from './transform-import-export'

  export default {
    components: {
      List,
      TransformImportExport
    },
    props: [ 'config', 'transform', 'index', 'readonly' ],
    data() {
      return {
        add_from: '',
        add_to: ''
      }
    },
    computed: {
      empty_value() {
        return this.$t('label.empty-value-indicator')
      },
      available_columns() {
        return this.$store.state.table.headers
      },
      can_add() {
        return this.add_from && this.add_to && this.add_from !== this.add_to
      },
      table_columns() {
        return _.compact([
          {
            slot: 'from',
            filter: 'KEY',
            label: this.$t('label.cellvalue')
          },
          {
            slot: 'to',
            filter: 'VALUE',
            label: this.$t('label.comparevalue')
          },
          !this.readonly && {
            slot: 'actions',
            width: 40,
            align: 'center'
          }
        ])
      },
    },
    methods: {
      addMatch() {
        if (!this.can_add) {
          return
        }
        this.$set(this.transform.table, this.add_from, this.add_to)
        this.add_from = this.add_to = ''
      },
      deleteItem(key) {
        this.transform.table = _.omit(this.transform.table, key)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .spacer {
    color: $col-primary;
  }

  .add-inputs {
    display: flex;

    .spacer {
      margin: 0 0.5rem
    }

    .el-button {
      margin-left: 0.5rem;
    }
  }

  .table-list {
    height: 300px;
  }
</style>
