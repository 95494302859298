import _ from 'lodash'

export default {
  created() {
    this._throttledResize = _.throttle(() => {
      if (this.onWindowResize) {
        this.onWindowResize()
      }
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this._onResize)
    window.removeEventListener('resize', this._onResize)
    if (this._throttledResize) {
      this._throttledResize.cancel()
    }
  },
  beforeMount() {
    window.addEventListener('resize', this._onResize)
    window.addEventListener('orientationchange', this._onResize)
  },
  methods: {
    _onResize() {
      this._throttledResize()
    }
  }
}
