import api from '../services/api'

const state = {
  all: []
}

const mutations = {
  _load(state, configs) {
    state.all = configs
  },
  reset(state) {
    state.all = []
  }
}

const getters = {
}

const actions = {
  refresh({ commit, rootState }) {
    if (!rootState.auth.user) {
      commit('reset')
    }
    api.get('/configurations').then((configs) => commit('_load', configs), () => commit('reset'))
  }
}

export default { namespaced: true, state, mutations, actions, getters }
