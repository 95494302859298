<template>
  <div :class="[ 'header', { hidden } ]">
    <div class="content">
      <div class="top-row">
        <div class="title" :title="header">
          <span>{{header}}</span>
        </div>
        <div class="action">
          <Dropdown trigger="hover" @on-click="onSort">
            <span style="position:relative;">
              <Icon type="ios-arrow-dropup" :size="18"></Icon>
              <span style="position:absolute;left:0;top:-2px;">
                <Icon type="ios-arrow-dropdown" :size="18"></Icon>
              </span>
            </span>
            <DropdownMenu slot="list">
              <DropdownItem name="ascending">
                <Icon type="ios-arrow-dropup"></Icon>
                {{$t('action.sort_ascending')}}
              </DropdownItem>
              <DropdownItem name="descending">
                <Icon type="ios-arrow-dropdown"></Icon>
                {{$t('action.sort_descending')}}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="action" @click="onToggleHidden">
          <Poptip trigger="hover" :title="$t('label.tip')" :width="300" :word-wrap="true">
            <Icon :type="hidden ? 'ios-eye-outline' : 'ios-eye-off-outline'" :size="18"></Icon>
            <div slot="content" v-html="$t(hidden ? 'tooltip.show-column' : 'tooltip.hide-column', { header })"></div>
          </Poptip>
        </div>
        <div class="action" @click="onGotoCorrector">
          <Poptip trigger="hover" :title="$t('label.tip')" :width="300" :word-wrap="true">
            <Icon type="ios-medkit-outline" :size="18"></Icon>
            <div slot="content" v-html="$t('tooltip.open-corrector', { header })"></div>
          </Poptip>
        </div>
        <div class="action" @click="onGotoSummary">
          <Poptip trigger="hover" :title="$t('label.tip')" :width="300" :word-wrap="true">
            <Icon type="ios-color-filter-outline" :size="18"></Icon>
            <div slot="content" v-html="$t('tooltip.open-summary', { header })"></div>
          </Poptip>
        </div>
      </div>
      <div class="bottom-row">
        <div class="wrap-config-select">
          <div class="label">{{$t('label.column_configuration')}}:</div>
          <ConfigurationSelect v-model="config_id" small :disabled="hidden" :has-prefix="can_create_configurations" @on-prefix="onGotoConfig"></ConfigurationSelect>
        </div>
        <ReferenceSelect v-for="(r, ri) in references" :key="`ref${ri}`" :header="header" :reference="r"></ReferenceSelect>
        <div v-if="errors" class="fg-error">{{$t('label.configuration_error')}}:</div>
        <template v-for="(e, ei) in errors">
          <div :key="ei" class="error">
            <div v-for="(k, ki) of e.errors" :key="ki">
              <Icon type="ios-alert-outline"></Icon>
              <b v-if="e.step >= 0">#Schritt {{e.step}}</b>
              {{ $t(`transform-error.${k}`) }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div :class="[ 'sizer', { dragging, disabled: !!errors } ]" @mousedown="onDragStart"></div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import ReferenceSelect from '/components/reference-select'
  import ConfigurationSelect from '/components/configuration-select'

  export default {
    components: {
      ReferenceSelect,
      ConfigurationSelect
    },
    props: {
      header: {
        type: String,
        required: true
      },
      errors: {
        type: Array,
        default: null
      },
      complete: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dragging: false,
        last_drag_x: 0
      }
    },
    computed: {
      config_id: {
        get() {
          const settings = this.$store.state.table.settings
          return (settings[this.header] && settings[this.header].configuration) || ''
        },
        set(v) {
          this.$store.commit('table/setHeaderConfiguration', { header: this.header, config: v })
        }
      },
      hidden() {
        const settings = this.$store.state.table.settings[this.header]
        return !!(settings && settings.hidden)
      },
      references() {
        const cfg = this.$store.getters['table/headerInfos'][this.header].config
        return _.uniq(cfg && cfg._references)
      },
      can_create_configurations() {
        const user = this.$store.state.auth.user
        return user && (user.admin || (user.rights && user.rights.can_create_configurations))
      }
    },
    created() {
      document.addEventListener('mousemove', this.onDragMove, true)
      document.addEventListener('mouseup', this.onDragEnd, true)
    },
    beforeDestroy() {
      document.removeEventListener('mousemove', this.onDragMove, true)
      document.removeEventListener('mouseup', this.onDragEnd, true)
    },
    methods: {
      onDragStart(e) {
        if (this.errors) {
          return
        }
        this.dragging = true
        this.last_drag_x = e.clientX
      },
      onDragEnd() {
        this.dragging = false
      },
      onDragMove(e) {
        if (!this.dragging) {
          return
        }
        const header = this.header
        const dx = e.clientX - this.last_drag_x
        let width = this.$store.getters['table/headerInfos'][header].width + dx
        this.$store.commit('table/setHeaderWidth', { header, width })
        this.last_drag_x = e.clientX
      },
      onGotoConfig() {
        this.$bus.$emit('goto-config', { id: this.config_id, header: this.header })
      },
      onGotoSummary() {
        this.$bus.$emit('goto-summary', { header: this.header })
        /*
        if (this.$store.getters['table/headerInfos'][this.header].hidden) {
          this.$store.commit('table/setHeaderHidden', { header: this.header, hidden: false })
        }
        */
      },
      onGotoCorrector() {
        this.$bus.$emit('goto-corrector', { header: this.header })
        /*
        if (this.$store.getters['table/headerInfos'][this.header].hidden) {
          this.$store.commit('table/setHeaderHidden', { header: this.header, hidden: false })
        }
        */
      },
      onToggleHidden() {
        const hidden = !this.$store.getters['table/headerInfos'][this.header].hidden
        this.$store.commit('table/setHeaderHidden', { header: this.header, hidden })
      },
      onSort(mode) {
        this.$emit('sort', this.header, mode)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/theme';

  $sizer-width: 4px;
  $content-padding-h: $cell-h-padding;
  $content-padding-v: 4px;

  .header {
    display: table-cell;
    position: relative;
    background-color: $col-background;

    &.hidden {
      .content {
        .bottom-row, .top-row .title {
          opacity: 0.25;
        }
      }
    }
  }

  .content {
    width: 100%;
    padding: $content-padding-v $content-padding-h;
    padding-right: $content-padding-h + $sizer-width;
  }

  .top-row {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: $content-padding-v;

    > div {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }
    }

    .title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }

    .action {
      cursor: pointer;
      margin-right: 0.25em;
      transition: color 280ms ease;

      &:hover {
        color: $col-primary;
      }

      .ivu-icon {
        font-size: 16px;
      }
    }
  }

  .sizer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: $sizer-width;
    background-color: $col-sub;
    cursor: ew-resize;

    transition: background-color 280ms ease;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.dragging {
      cursor: col-resize;
      background-color: $col-primary;
    }
  }

  .error {
    text-align: left;
    font-weight: normal;
    color: $col-error;
    margin-bottom: 0.25em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wrap-config-select {
    margin-bottom: 4px;

    .label {
      font-size: 11px;
      margin-bottom: 3px;
    }
  }
</style>
