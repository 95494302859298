import _ from 'lodash'

const state = {
  focused: false,
  header: '',
  rows: []
}

const getters = {
  isEmpty: (state) => {
    return _.isEmpty(state.rows)
  }
}

const mutations = {
  reset(state, opt) {
    state.header = (opt && opt.header) || ''
    state.rows = []
    state.focused = false
  },
  setMultiple(state, opt) {
    state.header = opt.header
    state.rows = _.concat(opt.append ? state.rows : [], opt.rows);
    state.rows = _.uniq(state.rows)
  },
  addRows(state, opt) {
    if (state.header !== opt.header) {
      state.header = opt.header || ''
      state.rows = []
      state.focused = false
    }
    const add = _.castArray(opt.rows)
    for (const r of add) {
      const idx = state.rows.indexOf(r)
      if (idx < 0) {
        state.rows.push(r)
      } else if (opt.toggle) {
        state.rows.splice(idx, 1)
      }
    }
    if (_.isEmpty(state.rows)) {
      state.focused = false
    }
  },
  setFocused(state, v) {
    state.focused = _.isEmpty(state.rows) ? false : (v || false)
  }
}

export default { namespaced: true, state, getters, mutations }
