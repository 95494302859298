import _ from 'lodash'
import EventEmitter from 'eventemitter3'
import axios from 'axios'

//-----------------------------------------------------------------------------

const PRODUCTION = process.env.NODE_ENV === 'production'

//-----------------------------------------------------------------------------

class API extends EventEmitter {
  constructor(opt) {
    super()
    this._request_counter = 0
    this.options = _.defaultsDeep({}, opt, {
      token_key: '_auth_token'
    })
    this.connection = axios.create({
      baseURL: this.options.endpoint
    })
    this.setToken(this.options.token_key && localStorage.getItem(this.options.token_key))
  }
  setToken(token) {
    token = token || ''
    if (this.options.token_key) {
      localStorage.setItem(this.options.token_key, token)
    }
    this.connection.defaults.headers.authorization = token && `Bearer ${token}`
  }
  get hasOpenRequests() {
    return this._request_counter > 0
  }
  isCancelError(err) {
    return axios.isCancel(err)
  }
  newCancelToken(fn) {
    return new axios.CancelToken(fn)
  }
  async _request(config) {
    const promise = new Promise((resolve, reject) => {
      this._request_counter = Math.max(0, this._request_counter + 1)
      this.emit('request-started')
      this.connection.request(config)
        .then((res) => {
          this._request_counter = Math.max(0, this._request_counter - 1)
          this.emit('request-successful')
          resolve(res.data || res)
        })
        .catch((err) => {
          this._request_counter = Math.max(0, this._request_counter - 1)
          this.emit('request-failed')
          if (err.response && err.response.status === 401) {
            this.setToken(null)
          }
          reject(_.get(err, 'response.data', err.response || err))
        })
    })
    return promise
  }
  async post(url, data, config) {
    return this._request(_.assign({}, config, { method: 'post', url, data }))
  }
  async put(url, data, config) {
    return this._request(_.assign({}, config, { method: 'put', url, data }))
  }
  async get(url, config) {
    return this._request(_.assign({}, config, { method: 'get', url }))
  }
  async delete(url, config) {
    return this._request(_.assign({}, config, { method: 'delete', url }))
  }
}

//-----------------------------------------------------------------------------

export default new API({
  endpoint: PRODUCTION ? "https://cleandata.24relations.de/api" : "//cleandata:8100"
})
