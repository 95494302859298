<template>
  <Modal v-model="is_open" :width="960" :styles="{ top: '48px' }" :z-index="6000" :title="$t('label.import_wizard')" @on-cancel="cancel">
    <table v-if="is_open">
      <thead>
        <th class="header">{{$t('label.delivered_column')}}</th>
        <th style="width:300px;">{{$t('label.column_configuration')}}</th>
        <th style="width:300px;">{{$t('label.description')}}</th>
      </thead>
      <tbody>
        <tr v-for="header in $store.state.table.header" :key="header">
          <td class="header">{{header}}</td>
          <td>
            <ConfigurationSelect :value="(headerInfos[header].config && headerInfos[header].config._id) || ''" :has-prefix="false" :header="header" @input="onConfigSelect"></ConfigurationSelect>
            <ReferenceSelect v-for="(r, ri) in getReferences(header)" :key="`ref${ri}`" :header="header" :reference="r" :plain="true"></ReferenceSelect>
          </td>
          <td>
            <div class="hint">{{headerInfos[header].config && headerInfos[header].config.description}}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div slot="footer">
      <Button @click="cancel">{{$t('action.cancel')}}</Button>
      <Button type="primary" :disabled="!can_apply" @click="apply">{{$t('action.apply')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import _ from 'lodash'
  import ReferenceSelect from '/components/reference-select'
  import ConfigurationSelect from '/components/configuration-select'

  export default {
    components: {
      ReferenceSelect,
      ConfigurationSelect
    },
    data() {
      return {
        is_open: false,
        backup: {}
      }
    },
    computed: {
      headerInfos() {
        return this.$store.getters['table/headerInfos']
      },
      can_apply() {
        let refs = 0
        for (const header of this.$store.state.table.header) {
          const info = this.$store.getters['table/headerInfos'][header]
          if (!info.config) {
            continue
          }
          for (const r of info.config._references) {
            if (!this.$store.state.table.settings[header].references[r]) {
              refs += 1
            }
          }
        }
        return refs <= 0
      }
    },
    methods: {
      open() {
        this.is_open = true
        this.backup = {}
        for (const header of this.$store.state.table.header) {
          const info = this.$store.getters['table/headerInfos'][header]
          const settings = this.$store.state.table.settings[header]
          this.backup[header] = {
            config: (info.config && info.config._id) || '',
            references: _.clone(settings && settings.references)
          }
          if (!info.config) {
            for (const c of this.$store.state.configurations.all) {
              const re = new RegExp(_.escapeRegExp(c.name), 'gi')
              if (re.test(header)) {
                this.$store.commit('table/setHeaderConfiguration', { header, config: c._id })
                break
              }
              /*
              // TODO: auto match references
              for (const r of c._references) {
                console.log(header, c.name, r)
              }
              */
            }
          }
        }
      },
      close() {
        this.is_open = false
      },
      cancel() {
        for (const header of this.$store.state.table.header) {
          this.$store.commit('table/setHeaderConfiguration', { header, config: this.backup[header].config })
          if (this.backup[header].references) {
            console.log(header, this.backup[header].references)
            for (const r in this.backup[header].references) {
              this.$store.commit('table/setHeaderReference', { header, reference:r, value: this.backup[header].references[r] || '' })
            }
          }
        }
        this.close()
      },
      apply() {
        this.close()
        //this.$bus.$emit('open-statistics')
      },
      onConfigSelect(config, header) {
        this.$store.commit('table/setHeaderConfiguration', { header, config })
      },
      getReferences(header) {
        const cfg = this.headerInfos[header].config
        return _.uniq(cfg && cfg._references)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      white-space: nowrap;
      text-align: left;
    }

    th, td {
      padding: 0.4em 0;
      padding-left: 1em;

      &.header {
        text-align: right;
      }
    }

    tr {
      &:nth-child(odd) {
        background-color: $col-divider;
      }
    }
  }
</style>
