<template>
  <div>
    <input ref="file_input" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:none;" @change="uploadEntries">
    <Dropdown placement="bottom-start" trigger="click" @on-click="onImport">
      <Button icon="ios-cloud-upload" :loading="importing" :disabled="importing">
        {{ $t('action.import_entries') }}
      </Button>
      <DropdownMenu slot="list">
        <DropdownItem name="replace" :disabled="!canReplace">{{ $t('label.replace_existing_entries') }}</DropdownItem>
        <DropdownItem name="concat" :disabled="!canConcat">{{ $t('label.concat_existing_entries') }}</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <template v-if="hasExport">
      &nbsp;&nbsp;
      <Button icon="ios-cloud-download" :loading="exporting" :disabled="exporting" @click="onExport">
        &nbsp;{{ $t('action.export_entries') }}
      </Button>
    </template>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { saveAs } from 'file-saver'
  import moment from 'moment'
  import sanitizeFilename from 'sanitize-filename'
  import APIMixin from '/mixins/api'

  export default {
    mixins: [
      APIMixin
    ],
    props: {
      config: {
        type: Object,
        required: true
      },
      transformIndex: {
        type: Number,
        required: true
      },
      prefix: {
        type: String,
        required: true
      },
      hasExport: {
        type: Boolean,
        default: true
      },
      canReplace: {
        type: Boolean,
        default: true
      },
      canConcat: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        importing: false,
        exporting: false,
        transform_import_mode: ''
      }
    },
    methods: {
      uploadEntries(e) {
        const file = _.get(e, 'target.files[0]')
        const fn = (file && file.name) || ''
        const ext = _.toLower(fn).split('.').pop()
        if (ext !== 'xlsx') {
          return
        }
        const data = new FormData()
        data.append('mode', this.transform_import_mode)
        data.append('file', file)
        e.target.value = ''
        this.import_mode = ''
        this.importing = true
        this.apiPost('import', {
          url: `/configuration/${this.config._id}/import-transform-data/${this.transformIndex}`,
          data
        }).then(() => {
          this.importing = false
          this.$emit('imported')
        }, (err) => {
          this.importing = false
          this.$reportError(err)
        })
      },
      onImport(cmd) {
        if (this.importing) {
          return
        }
        this.transform_import_mode = cmd
        this.$refs.file_input.click()
      },
      onExport() {
        this.exporting = true
        this.apiGet('export', {
          url: `/configuration/${this.config._id}/export-transform-data/${this.transformIndex}`,
          responseType: 'blob'
        }).then((res) => {
          this.exporting = false
          const fn = `${moment().format('YYYYMMDD_HHmm')}_Export-${this.prefix}_${this.config.name}.xlsx`
          saveAs(res, sanitizeFilename(fn))
        }, (err) => {
          this.exporting = false
          this.$reportError(err)
        })
      }
    }
  }
</script>
