<template>
  <Modal v-model="is_open" :width="1024" :styles="{ top: '48px' }" :title="$t('label.statistics')" @on-cancel="close">
    <div v-if="job && job.results">
      <p class="message" v-html="$t('statistics.intro', { table: $store.state.table.name, rows: $store.state.table.rows })"></p>
      <table class="results">
        <thead>
          <th>{{$t('label.delivered_column')}}</th>
          <th>{{$t('label.column_configuration')}}</th>
          <!--<th>{{$t('label.rows')}}</th>-->
          <th>
            <span>{{$t('label.empty')}}</span>
            <!--<Tip :text="$t('hint.statistics_before_after')"></Tip>-->
          </th>
          <th>
            <span>{{$t('label.errors')}}</span>
            <!--<Tip :text="$t('hint.statistics_before_after')"></Tip>-->
          </th>
          <th>{{$t('label.corrected')}}</th>
          <th>
            {{$t('label.duplicates')}}
            <tip :text="$t('hint.how_duplicates_are_counted')" placement="top-start"></tip>
            ({{$t('label.before')}})
          </th>
          <th>
            {{$t('label.duplicates')}}
            <tip :text="$t('hint.how_duplicates_are_counted')" placement="top-start"></tip>
            ({{$t('label.after')}})
          </th>
        </thead>
        <tbody>
          <tr v-for="col in job.results.columns" :key="col.header">
            <td>
              <span class="clickable" @click="onGotoSummary(col.header)">{{col.header}}</span>
            </td>
            <td>{{col.config || '-'}}</td>
            <!--<td>{{col.total}}</td>-->
            <td>
              <!--
              <template v-if="col.config">{{col.empty_before}} / {{col.empty_after}}</template>
              <template v-else>{{col.empty_before}}</template>
              -->
              {{col.empty_before}}
            </td>
            <td>
              <!--
              <template v-if="col.config">{{col.error_before}} / {{col.error_after}}</template>
              <template v-else>-</template>
              -->
              <template v-if="col.config">{{col.error_before}}</template>
              <template v-else>-</template>
            </td>
            <td>{{col.config ? col.corrected_after : '-'}}</td>
            <td>{{col.duplicates_before}}</td>
            <td>{{col.duplicates_after}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <SpinnerProgress :text="$t(`action.computing`)"></SpinnerProgress>
    </div>
    <div slot="footer">
      <Button v-if="job && job.results" icon="ios-cloud-download-outline" @click="onDownload">{{$t('action.download')}}</Button>
      <Button @click="close">{{$t((job && !job.finished) ? 'action.cancel' : 'action.close')}}</Button>
    </div>
  </Modal>
</template>

<script>
  import moment from 'moment'
  import { saveAs } from 'file-saver'
  import sanitizeFilename from 'sanitize-filename'
  import APIMixin from '/mixins/api'
  //import Tip from '/components/tip'
  import MethodIntervalMixin from '/mixins/method-interval'
  import SpinnerProgress from '/components/spinner-progress'

  export default {
    components: {
      //Tip,
      SpinnerProgress
    },
    mixins: [
      APIMixin,
      MethodIntervalMixin
    ],
    data() {
      return {
        is_open: false,
        settings: {},
        job: null
      }
    },
    methods: {
      async open() {
        this.is_open = true
        const data = {
          table: this.$store.state.table._id,
          settings: this.settings
        }
        this.apiPost('statistics', { url: '/statistics-job', data })
          .then((job) => {
            if (!this.is_open) {
              return
            }
            this.job = job
          }, (err) => {
            this.$reportError(err)
            this.job = null
          })
        this.methodInterval('refresh', this.onRefresh, 1000)
      },
      close() {
        this.is_open = false
        this.cancelMethodInterval('refresh')
        this.apiCancelAll()
        this.apiGet('cancel', { url: `/statistics-job/${this.job._id}/cancel` })
        this.job = null
      },
      onRefresh() {
        if (!this.is_open || !this.job || this.job.results) {
          return
        }
        this.apiGet('refresh', { url: `/statistics-job/${this.job._id}` })
          .then((job) => {
            if (!this.is_open) {
              return
            }
            this.job = job
          }, (err) => {
            this.$reportError(err)
            this.job = null
          })
      },
      onGotoSummary(header) {
        this.close()
        this.$bus.$emit('goto-summary', { header })
      },
      onDownload() {
        this.apiPost('download', {
          url: '/render/statistics',
          data: { data: this.job.results },
          responseType: 'blob'
        }).then((res) => {
          const fn = `${moment().format('YYYYMMDD_HHmm')}_${this.$store.state.table.name}_Statistik.24myCleanData.xlsx`
          saveAs(res, sanitizeFilename(fn))
          this.close()
        }, (err) => {
          this.$reportError(err)
          this.close()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .results {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid $col-border;

    th {
      white-space: nowrap;
      border-bottom: 1px solid $col-border;
    }

    th, td {
      padding: 0.15em 0.25em;
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }

    tr {
      &:nth-child(even) {
        background-color: $col-divider;
      }
    }
  }
</style>
