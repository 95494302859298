<template>
  <Form :label-width="200" @submit.native.prevent>
    <FormItem :label="$t('label.all_whitespace')">
      <i-switch v-model="transform.all" :disabled="readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.leading_whitespace')">
      <i-switch v-model="transform.leading" :disabled="transform.all || readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.trailing_whitespace')">
      <i-switch v-model="transform.trailing" :disabled="transform.all || readonly"></i-switch>
    </FormItem>
    <FormItem :label="$t('label.redundant_whitespace')">
      <i-switch v-model="transform.redundant" :disabled="transform.all || readonly"></i-switch>
    </FormItem>
  </Form>
</template>

<script>
  export default {
    props: [ 'transform', 'readonly' ]
  }
</script>
