<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem>
      <List :data="transform.map" :columns="map_columns" class="map-list">
        <span slot="from" slot-scope="{ itemIndex }">
          {{itemIndex || empty_value}}
        </span>
        <span slot="to" slot-scope="{ item }">
          {{item || empty_value}}
        </span>
        <span slot="actions" slot-scope="{ itemIndex }">
          <span class="action-btn danger" @click="deleteItem(itemIndex)">
            <Icon type="ios-trash-outline"></Icon>
          </span>
        </span>
      </List>
    </FormItem>

    <FormItem v-if="!readonly">
      <div class="add-inputs">
        <Input v-model.trim="add_from" type="text" :placeholder="$t('label.map_from')"></Input>
        <div class="spacer"><Icon type="ios-arrow-forward"></Icon></div>
        <Input v-model.trim="add_to" type="text" :placeholder="$t('label.map_to')" @on-enter="addMapping"></Input>
        <div class="spacer"></div>
        <Button type="primary" :disabled="!can_add" icon="ios-add-circle-outline" @click="addMapping">{{ $t('action.add') }}</Button>
      </div>
    </FormItem>

    <TransformImportExport v-if="!readonly" :config="config" :transform-index="index" prefix="Zuordnung" @imported="$emit('refresh')"></TransformImportExport>
  </Form>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import TransformImportExport from './transform-import-export'

  export default {
    components: {
      List,
      TransformImportExport
    },
    props: [ 'config', 'index', 'transform', 'readonly' ],
    data() {
      return {
        import_mode: '',
        add_from: '',
        add_to: ''
      }
    },
    computed: {
      map_columns() {
        return _.compact([
          {
            slot: 'from',
            filter: 'KEY',
            label: this.$t('label.map_from')
          },
          {
            slot: 'to',
            filter: 'VALUE',
            label: this.$t('label.map_to')
          },
          !this.readonly && {
            slot: 'actions',
            width: 40,
            align: 'center'
          }
        ])
      },
      can_add() {
        return this.add_from && this.add_from !== this.add_to
      },
      empty_value() {
        return this.$t('label.empty-value-indicator')
      }
    },
    methods: {
      addMapping() {
        if (!this.can_add) {
          return
        }
        this.$set(this.transform.map, this.add_from, this.add_to || '')
        this.add_from = this.add_to = ''
      },
      deleteItem(key) {
        this.transform.map = _.omit(this.transform.map, key)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .spacer {
    color: $col-primary;
  }

  .search-box {
    margin-bottom: 10px;
  }

  .add-inputs {
    display: flex;

    .spacer {
      margin: 0 0.5rem;
    }

    .el-button {
      margin-left: 0.5rem;
    }
  }

  .map-list {
    height: 300px;
  }
</style>
