<template>
  <div class="configuration-editor">
    <div class="heading">{{$t('label.column_configuration')}}:</div>
    <div class="inner">
      <Spin v-if="api_requests.num_pending > 0 || refreshing" fix></Spin>

      <div class="header">
        <div class="select">
          <ConfigurationSelect v-model="id" :has-prefix="false"></ConfigurationSelect>
        </div>
        <ButtonGroup>
          <Button icon="ios-browsers-outline" @click="empty">{{$t('action.new')}}</Button>
          <Button icon="ios-trash-outline" :disabled="cant_delete" @click="del">&nbsp;</Button>
        </ButtonGroup>
      </div>

      <div v-if="config" class="editor">
        <div v-if="id && is_owned_by_other_admin" class="config-hint">
          <Alert type="info" show-icon>{{$t('hint.configuration_owned_by_other_admin')}}</Alert>
        </div>
        <div v-else-if="id && !is_owned" class="config-hint">
          <Alert type="info" show-icon>{{$t('hint.public_configuration_readonly')}}</Alert>
        </div>
        <template v-if="!readonly">
          <Form ref="form" :model="config" :label-width="100" @submit.native.prevent>
            <FormItem prop="name" :label="$t('label.name')">
              <Input :value="config.name" search :enter-button="$t('action.save')" @on-search="onNameEnter"></Input>
            </FormItem>
            <FormItem prop="description" :label="$t('label.description')">
              <Input :value="config.description" type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" @on-enter="onDescriptionEnter"></Input>
            </FormItem>
            <FormItem v-if="is_admin" prop="public" :label="$t('label.public')">
              <i-switch v-model="config.public" :disabled="readonly"></i-switch>
            </FormItem>
            <FormItem>
              <span slot="label">
                <Tip v-if="is_admin" :text="$t('hint.public_config_and_user_tags')"></Tip>
                {{$t('label.tags')}}
              </span>
              <TagList v-model="config.tags"></TagList>
            </FormItem>
          </Form>
        </template>
        <template v-else>
          <div v-if="config.description" class="hint config-hint">{{config.description}}</div>
          <div class="config-tags">
            <TagList :value="config.tags" :readonly="true"></TagList>
          </div>
        </template>

        <div class="transforms-header">
          <div class="title">{{$t('label.transforms')}}</div>
          <div class="description">{{$t('label.transforms-intro')}}</div>
        </div>

        <template v-for="(t, ti) in config.transforms">
          <div :key="`header-${ti}`" :class="[ 'transform-entry', { active: ti === active_transform } ]">
            <div class="index" @click="onToggleTransform(ti)">
              {{ti+1}}.
            </div>
            <div class="title" @click="onToggleTransform(ti)">
              <div class="name">{{$t(`transform-name.${t.name}`)}}</div>
              <div v-if="$te(`transform-hint.${t.name}`)" class="transform-entry-description" v-html="$t(`transform-hint.${t.name}`)"></div>
            </div>
            <div v-if="!readonly">
              <ButtonGroup size="small">
                <Button icon="ios-arrow-dropup" :disabled="ti <= 0" @click="onMoveTransform(ti, -1)"></Button>
                <Button icon="ios-arrow-dropdown" :disabled="ti >= config.transforms.length-1" @click="onMoveTransform(ti, 1)"></Button>
                <Button icon="ios-trash-outline" @click="onRemoveTransform(ti)"></Button>
              </ButtonGroup>
            </div>
          </div>
          <div v-if="ti === active_transform" :key="`entry-${ti}`" class="transform-editor">
            <edit-generic-empty v-if="t.name === 'generic-empty'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></edit-generic-empty>
            <edit-generic-number v-else-if="t.name === 'generic-number'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></edit-generic-number>
            <edit-generic-match v-else-if="t.name === 'generic-match'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></edit-generic-match>
            <edit-generic-map v-else-if="t.name === 'generic-map'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></edit-generic-map>
            <EditGenericEmail v-else-if="t.name === 'generic-email'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericEmail>
            <EditGenericVIN v-else-if="t.name === 'generic-vin'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericVIN>
            <EditGenericDate v-else-if="t.name === 'generic-date'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericDate>
            <EditGenericSpaces v-else-if="t.name === 'generic-spaces'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericSpaces>
            <EditGenericReplace v-else-if="t.name === 'generic-replace'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></EditGenericReplace>
            <EditGenericPhoneNumber v-else-if="t.name === 'generic-phone-number'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericPhoneNumber>
            <EditGenericVehicleLicense v-else-if="t.name === 'generic-vehicle-license'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericVehicleLicense>
            <EditGenericCharacters v-else-if="t.name === 'generic-characters'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditGenericCharacters>
            <EditMileagePlausibility v-else-if="t.name === 'mileage-plausibility'" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditMileagePlausibility>
            <EditCityPlausibility v-else-if="t.name === 'city-plausibility'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></EditCityPlausibility>
            <edit-generic-enum v-else-if="t.name === 'generic-enum'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></edit-generic-enum>
            <edit-generic-zipcode v-else-if="t.name === 'generic-zipcode'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly"></edit-generic-zipcode>
            <EditPSDMatchPhoneIdent v-else-if="t.name === 'psd-match-phone-ident'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></EditPSDMatchPhoneIdent>
            <EditRoutingRequest v-else-if="t.name === 'routing-request'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></EditRoutingRequest>
            <GenericLinkToGlobalVehicleBrandModel v-else-if="t.name === 'global-vehicle-brand' || t.name === 'global-vehicle-brand-model' || t.name === 'global-vehicle-model' || t.name === 'reconstruct-vehicle-brand'" :config="config" :index="ti" :transform="t" :options="all_transforms[t.name].options" :readonly="readonly" @refresh="onRefresh"></GenericLinkToGlobalVehicleBrandModel>
            <div v-else class="fg-error">{{t}}</div>
          </div>
          <div v-if="ti < config.transforms.length-1" :key="`sep-${ti}`" class="transform-separator"></div>
        </template>

        <div v-if="!readonly" class="transforms-add">
          <Dropdown trigger="click" @on-click="onAddTransform">
            <Button type="primary" ghost :disabled="!can_add_transforms" icon="ios-add-circle-outline">
              {{$t('action.add-transform')}}
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-for="t in available_transforms" :key="t.value" :name="t.value">{{t.label}}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import Tip from '/components/tip'
  import APIMixin from '/mixins/api'
  import TagList from '/components/tag-list'
  import ConfigurationSelect from '/components/configuration-select'
  import EditGenericEmpty from './edit-generic-empty'
  import EditGenericNumber from './edit-generic-number'
  import EditGenericMatch from './edit-generic-match'
  import EditGenericMap from './edit-generic-map'
  import EditGenericEnum from './edit-generic-enum'
  import EditGenericZipcode from './edit-generic-zipcode'
  import EditGenericEmail from './edit-generic-email'
  import EditGenericVIN from './edit-generic-vin'
  import EditGenericDate from './edit-generic-date'
  import EditGenericSpaces from './edit-generic-spaces'
  import EditGenericReplace from './edit-generic-replace'
  import EditGenericCharacters from './edit-generic-characters'
  import EditGenericPhoneNumber from './edit-generic-phone-number'
  import EditGenericVehicleLicense from './edit-generic-vehicle-license'
  import EditMileagePlausibility from './edit-mileage-plausibility'
  import EditCityPlausibility from './edit-city-plausibility'
  import EditPSDMatchPhoneIdent from './edit-psd-match-phone-ident'
  import EditRoutingRequest from './edit-routing-request'
  import GenericLinkToGlobalVehicleBrandModel from './generic-link-to-global-vehicle-brand-model'

  export default {
    components: {
      Tip,
      TagList,
      ConfigurationSelect,
      EditGenericEmpty,
      EditGenericNumber,
      EditGenericMatch,
      EditGenericMap,
      EditGenericZipcode,
      EditGenericEnum,
      EditGenericEmail,
      EditGenericVIN,
      EditGenericDate,
      EditGenericSpaces,
      EditGenericReplace,
      EditGenericCharacters,
      EditGenericPhoneNumber,
      EditGenericVehicleLicense,
      EditMileagePlausibility,
      EditCityPlausibility,
      EditPSDMatchPhoneIdent,
      EditRoutingRequest,
      GenericLinkToGlobalVehicleBrandModel
    },
    mixins: [
      APIMixin
    ],
    data() {
      return {
        refreshing: false,
        config: null,
        active_transform: -1,
        all_transforms: {}
      }
    },
    computed: {
      id: {
        get() {
          return (this.config && this.config._id) || ''
        },
        set(v) {
          this.load(v)
        }
      },
      can_add_transforms() {
        return !!(this.config && this.config._id)
      },
      cant_delete() {
        return !this.config || !this.is_owned
      },
      is_owned() {
        const user = this.$store.state.auth.user
        return this.config && user && (!this.config.owner || this.config.owner === user._id)
      },
      is_owned_by_other_admin() {
        const user = this.$store.state.auth.user
        return user && user.admin && this.config && this.config.owner !== user._id
      },
      readonly() {
        return !this.is_owned
      },
      is_admin() {
        const user = this.$store.state.auth.user
        return user && user.admin
      },
      available_transforms() {
        let trans = _.compact(_.map(this.all_transforms, (t, name) => {
          if (_.find(this.config.transforms, { name }) && !t.allow_multiple) {
            return
          }
          return {
            value: name,
            label: this.$t(`transform-name.${name}`)
          }
        }))
        trans = _.sortBy(trans, 'label')
        return trans
      }
    },
    watch: {
      'config': {
        deep: true,
        handler() {
          if (this._updatingAfterSaveOrLoad) {
            this._updatingAfterSaveOrLoad = false
            return
          }
          this.saveIfPossible()
        }
      },
      'config.name'() {
        this.$nextTick(() => {
          this.config.name = _.replace(this.config.name, /[.$=_|#<>\][{}\\/]/g, '')
        })
      }
    },
    created() {
      this.loadTransforms()
      this.$bus.$on('mapping-performed', this.onMappingPerformed)
    },
    beforeDestroy() {
      this.$bus.$off('mapping-performed', this.onMappingPerformed)
    },
    methods: {
      empty() {
        this.config = {
          name: '',
          description: '',
          public: false,
          transforms: [],
          tags: []
        }
      },
      async loadTransforms() {
        this.all_transforms = await this.apiGet('transforms', { url: '/transforms' })
      },
      isNameInvalid(name) {
        return !name || /[.$=|#<>\][{}\\/]/g.test(name)
      },
      isNameTaken(name) {
        return _.some(this.$store.state.configurations.all, (cfg) => {
          return cfg.name === name && cfg._id !== this.config._id
        })
      },
      saveIfPossible() {
        if (!this.config || this.isNameInvalid(this.config.name) || this.isNameTaken(this.config.name)) {
          return
        }
        this.apiPost('config', { url: '/configuration', data: this.config })
          .then((config) => {
            this._updatingAfterSaveOrLoad = true
            this.config = config
            this.refreshing = true
            this.$store.dispatch('configurations/refresh')
              .then(() => {
                this.refreshing = false
              }, (err) => {
                this.refreshing = false
                this.$reportError(err)
              })
          })
      },
      del() {
        if (!this.config._id) {
          this.id = ''
          return
        }
        this.$Modal.confirm({
          title: this.$t('label.attention'),
          content: this.$t('confirm.delete-configuration', { name: this.config.name }),
          okText: this.$t('action.delete'),
          onOk: () => {
            this.apiDel('config', { url: `/configuration/${this.config._id}`})
              .then(() => {
                this.id = ''
                this.$store.dispatch('configurations/refresh')
              })
          }
        })
      },
      load(id) {
        if (!id) {
          this.config = null
          this.active_transform = -1
          return
        }
        this.apiGet('config', { url: `/configuration/${id}`})
          .then((config) => {
            this._updatingAfterSaveOrLoad = true
            this.config = config
          })
      },
      onToggleTransform(idx) {
        this.active_transform = this.active_transform === idx ? -1 : idx
      },
      async onAddTransform(name) {
        const settings = await this.apiGet('settings', { url: `/transform/${name}/settings`})
        this.config.transforms.push(settings)
        this.active_transform = _.size(this.config.transforms) - 1
      },
      onRemoveTransform(idx) {
        this.$Modal.confirm({
          title: this.$t('label.attention'),
          content: this.$t('confirm.delete-transform', { name: `${idx+1}.` }),
          okText: this.$t('action.delete'),
          onOk: () => {
            this.config.transforms.splice(idx, 1)
            this.active_transform = _.isEmpty(this.config.transforms) ? -1 : this.active_transform
          }
        })
      },
      onMoveTransform(idx, dir) {
        const o = this.config.transforms[idx]
        this.$set(this.config.transforms, idx, this.config.transforms[idx + dir])
        this.$set(this.config.transforms, idx + dir, o)
        this.$nextTick(() => {
          this.active_transform = _.findIndex(this.config.transforms, { name: o.name })
        })
      },
      onNameEnter(val) {
        if (!val || !this.config) {
          return
        }
        if (this.isNameInvalid(val)) {
          this.$Message.error(this.$t('hint.name_contains_invalid_characters'))
        }
        if (this.isNameTaken(val)) {
          this.$Message.error(this.$t('hint.name_already_in_use'))
        }
        this.config.name = val
      },
      onDescriptionEnter(e) {
        if (this.config) {
          this.config.description = e.target.value
        }
      },
      onRefresh() {
        this.load(this.config._id)
        this.$bus.$emit('table-request-refresh')
      },
      onMappingPerformed(report) {
        if (this.config && this.config._id === report.config) {
          this.load(this.config._id)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '/styles/theme';

  .configuration-editor {
    position: relative;
    padding-bottom: 2rem;

    .inner {
      position: relative;
    }
  }

  .heading {
    font-weight: bold;
    background-color: $col-background;
    padding: 0.5rem;
    font-size: 1.16em;
    user-select: none;
  }

  .header {
    display: flex;
    padding: 0.5rem;

    .select {
      flex: 1;
      margin-right: 1rem;
    }
  }

  .editor {
    .config-hint {
      padding: 0.5rem;
    }

    form {
      padding: 0.5rem;
    }

    .config-hint, .config-tags {
      padding: 0.5rem;
    }
  }

  .transforms-header {
    margin-top: 1rem;
    border-top: 1px solid $col-dark-border;
    padding: 0.5em;
    padding-bottom: 2.5em;
    user-select: none;

    .title {
      font-size: 1.42em;
      line-height: 160%;
      font-weight: bold;
    }

    .description {
      font-style: italic;
    }
  }

  .transform-entry {
    user-select: none;
    cursor: pointer;
    padding: 0.5em;
    background-color: $col-background;
    border-bottom: 1px solid #fff;
    transition: background-color 280ms ease;
    display: flex;
    justify-content: center;

    &.active {
      background-color: $col-primary-very-light;
    }

    .index {
      font-size: 1.42rem;
      font-weight: bold;
      margin-right: 0.5rem;
      line-height: 110%;
    }

    .title {
      flex: 1;
      margin-right: 0.5rem;

      .name {
        font-size: 0.82em;
      }

    }
  }

  .transform-editor {
    padding: 0.5em;
    margin-bottom: 2em;
  }

  .transforms-add {
    margin-top: 2em;
    text-align: center;
  }

  .transform-separator {
    height: 1.26rem;
    margin: 0.5em 0;
    background-image: url("/assets/images/double-arrow-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
</style>

<style lang="scss">
  @import '/styles/theme';

  .transform-entry-description {
    font-style: italic;

    em {
      font-weight: bold;
    }
  }
</style>
