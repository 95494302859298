<template>
  <Form :label-width="0" @submit.native.prevent>
    <FormItem>
      <List :data="transform.map" :columns="map_columns" style="height:300px;">
        <span slot="from" slot-scope="{ itemIndex }">
          {{itemIndex || empty_value}}
        </span>
        <span slot="to" slot-scope="{ item }">
          {{item || empty_value}}
        </span>
      </List>
    </FormItem>

    <FormItem>
      <Alert>
        Format der Importtabelle:
        <template slot="desc">
          Kundennummer | Telefon 1 | Telefon 2 | Telefon 3 | Telefon 4
        </template>
      </Alert>
    </FormItem>

    <FormItem>
      <TransformImportExport v-if="!readonly" :config="config" :transform-index="index" prefix="PSDKnrTelIdent" :has-export="false" @imported="$emit('refresh')"></TransformImportExport>
    </FormItem>
  </Form>
</template>

<script>
  import _ from 'lodash'
  import List from '/components/list'
  import TransformImportExport from './transform-import-export'

  export default {
    components: {
      List,
      TransformImportExport
    },
    props: [ 'config', 'index', 'transform', 'options', 'readonly' ],
    computed: {
      map_columns() {
        return _.compact([
          {
            slot: 'from',
            filter: 'KEY',
            label: this.$t('label.map_from')
          },
          {
            slot: 'to',
            filter: 'VALUE',
            label: this.$t('label.map_to')
          },
          !this.readonly && {
            slot: 'actions',
            width: 40,
            align: 'center'
          }
        ])
      }
    }
  }
</script>
